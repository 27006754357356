import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import Transparent from '../images/sprite/transparent.gif'
import { suid } from 'rand-token';
import axios from 'axios';
import FirsttimeLogin from './Mobilefirstlogin';
import { toast } from 'react-toastify';
import './Loginmobile.css'; // CSS for premium animations

const cookies = new Cookies();

export default function Loginmobile(props) {
	const [Socialicon, setSocialicon] = useState(0);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userid, setId] = useState('');
  const [password, setPassword] = useState('');
  const [captchaSuccess, setCaptcha] = useState(false);
  const [firstlogin, setfirstlogin] = useState(0);
  const [viewpass, setViewpass] = useState(false);
  const [captcha, setCaptchaCode] = useState('');

  function createCaptcha() {
    const captcha = Array.from({ length: 4 }, () => Math.floor(Math.random() * 10)).join('');
    setCaptchaCode(captcha);

    const canv = document.createElement('canvas');
    canv.id = 'captch';
    canv.width = 70;
    canv.height = 50;

    const ctx = canv.getContext('2d');
    ctx.font = '600 30px Arial';
    ctx.fillText(captcha, 0, 30);

    const captchaContainer = document.getElementById('popupcaptcha') || document.getElementById('captcha');
    captchaContainer.innerHTML = '';
    captchaContainer.appendChild(canv);
  }

  function validateCaptcha(event) {
    setCaptcha(event.target.value === captcha);
  }

  useEffect(() => {
    createCaptcha();
  }, []);

  const handleLogin = async () => {
    if (!userid) {
      toast.error('Username is empty', { position: toast.POSITION.TOP_CENTER });
      createCaptcha();
      return;
    }
    if (!password) {
      toast.error('Password cannot be blank!', { position: toast.POSITION.TOP_CENTER });
      createCaptcha();
      return;
    }
    if (!captchaSuccess) {
      toast.error('Captcha is not valid!', { position: toast.POSITION.TOP_CENTER });
      createCaptcha();
      return;
    }

    const token = userid.toUpperCase() === 'DEMO01' ? '123456789' : suid(16);
    let userId =  userid.toUpperCase()
    cookies.set('id', userId, { path: '/' });
    cookies.set('token', token, { path: '/' });
    cookies.set('password', password, { path: '/' });

    try {
      const result = await axios.post('https://liveapi247.live/api8/userlogin', {
        id: userId,
        password: password,
        token: token,
      });

      if (result.status === 201 || result.status === 202) {
        setfirstlogin(result.status === 201 ? 1 : 2);
      } else if (result.status === 200) {
        setLoggedIn(true);
        window.location.href = '/home';
      } else if (result.status === 205) {
        toast.warn('User Blocked!', { position: toast.POSITION.TOP_CENTER });
      } else {
        toast.warn('Username or password incorrect!', { position: toast.POSITION.TOP_CENTER });
      }
    } catch (error) {
      toast.error('An error occurred during login.', { position: toast.POSITION.TOP_CENTER });
    }
  };

  return (
    <div className="login-page">
      <div className="animated-background"></div>
      <div className="login-container">
        <header className="login-head" style={{border:"none"}}>
          <Link to="/" className="close ui-link" onClick={() => props.checkShowLogin(false)}></Link>
          <h1>cricbet247</h1>
        </header>
        <div className="form-wrapper">
          <dl className="form-login">
            <dd>
              <input type="email" value={userid} onChange={(e) => setId(e.target.value)} placeholder="Username" />
            </dd>
            <dd>
              <input type={viewpass ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
              {viewpass ? (
                <AiFillEye onClick={() => setViewpass(!viewpass)} className="eye-icon" />
              ) : (
                <AiFillEyeInvisible onClick={() => setViewpass(!viewpass)} className="eye-icon" />
              )}
            </dd>
            <dd>
              <input onChange={validateCaptcha} type="text" inputMode="numeric" maxLength="4" placeholder="Captcha" />
              <div id="popupcaptcha" className="captcha-container"></div>
            </dd>
            <dd>
              <button onClick={handleLogin} style={{    height: '12vw',lineHeight: '1'}} className="btn-send">Login</button>
            </dd>
          </dl>
        </div>
		<ul _ngcontent-njs-c67="" class="mob-inform-link login-supports" style={{ display: ' block' }}>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(4, true) }} >KYC</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(6, true) }}>About Us</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(1, true) }}>Privacy Policy</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(5, true) }}>Responsible Gaming</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(2, true) }}>Terms and Conditions</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(8, true) }}>Underage Gaming Policy</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(7, true) }}>Self-Exclusion Policy</a></li>
							<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(3, true) }}>Rules and Regulations</a></li>

						</ul>
						<div id="supportWrap" className="support-wrap" style={{width:"100%"}}>
							<div className="support-service">
								<a id="support_email" onClick={() => { setSocialicon(0) }} className={`support-mail ui-link ${(Socialicon === 0) ? "open" : "null"}`}>
									<img src={Transparent} title="Email" /></a>
								<a id="support_whatsapp" onClick={() => { setSocialicon(1) }} className={`support-whatsapp ui-link ${(Socialicon === 1) ? "open" : "null"}`}>
									<img src={Transparent} title="WhatsApp" /></a>
								<a
									id="support_telegram" onClick={() => { setSocialicon(2) }} className={`support-fb ui-link ${(Socialicon === 2) ? "open" : "null"}`}>
									<img src={Transparent} title="facebook" /></a>
								<a id="support_skype" onClick={() => { setSocialicon(3) }} className={`support-skype ui-link ${(Socialicon === 3) ? "open" : "null"}`} >
									<img src={Transparent} title="Skype" /></a>
								<a
									id="support_instagram" onClick={() => { setSocialicon(4) }} className={`support-ig ui-link ${(Socialicon === 4) ? "open" : "null"}`} >
									<img src={Transparent} title="Instagram" /></a>
							</div>
							<div className="support-info">
								<div id="supportDetail_email" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
									{Socialicon === 0 && <a className="ui-link" href="https://t.me/cricbet247_bet"><span>cricbet247_bet</span></a>}
								</div>

								<div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 1) ? "open" : "null"}`} >
									{Socialicon === 1 && <a class="ui-link" href="https://wa.me/+97000"><span>000</span></a>}
									{/* {Socialicon === 1 && <a class="ui-link" href="https://wa.me/000"><span>000</span></a>}
									{Socialicon === 1 && <a class="ui-link" href="https://wa.me/‪000"><span>‪000</span></a>} */}
								</div>
								<div id="supportDetail_telegram" className={`support-detail ${(Socialicon === 2) ? "open" : "null"}`} />
								{Socialicon === 2 && <a class="ui-link" href="#"><span>cricbet247official</span></a>}
								<div id="supportDetail_skype" className={`support-detail ${(Socialicon === 3) ? "open" : "null"}`} >
									{Socialicon === 3 && <a class="ui-link"><span>cricbet247official</span></a>}
								</div>
								<div id="supportDetail_instagram" className={`support-detail ${(Socialicon === 4) ? "open" : "null"}`} >
									{Socialicon === 4 && <a class="ui-link"><span>officialcricbet247</span></a>}
								</div>
							</div>
						</div>
      </div>
    </div>
  );
}