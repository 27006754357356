import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
import Cookies from 'universal-cookie';
import {useCheckBookmakerData, useCheckFancyData, useCheckTvData} from "../serives/react-query/api-hooks";
import {sportsKeyByName} from "../utils/constant";
import {inPlayDataFilter, sportsEventDataFilterCompetionName} from "../utils/sportsHelper";
import CompetionTab from "./components/competion-tab/competion-tab";
import {getAllEventsApiRq} from "../serives/react-query/api-actions";
require('moment-timezone');

const cookies = new Cookies();

export default function CricketMatches(props) {
  const match = props.data;

  const [competionDataList, setCompetionDataList] = useState({});
  useEffect(() => {
    if(match && match.length !== 0){
      sportsEventDataFilterCompetionName(match).then(cData=> {
        setCompetionDataList(cData.filterByGroupArr)
      })
    }

  }, [match]);


  // const [match, setMatch] = useState([]);
  // const [fancy, setFancy] = useState([]);
  // const [newrefresh, setnewrefresh] = useState(false);
  // const [tv, settv] = useState([])
  // const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);


  var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";



  const {data: fancy } = useCheckFancyData({  enabled: true, select: (data)=>  data.data});
  const {data: tv } = useCheckTvData({  enabled: true, select: (data)=>  data.data});
  const {data: bookmaker } = useCheckBookmakerData({  enabled: true, select: (data)=>  data.data});


  // vvvv
  // useEffect(() => {
  //
  //   // Axios.get(
  //   //   `https://liveapi247.live/api8/cricketData`)
  //   //
  //   //   .then(result => {
  //   //     result.data.map(item => {
  //   //       item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
  //   //       return item;
  //   //     })
  //   //
  //   //     result.data.sort(function (a, b) {
  //   //
  //   //       return moment(a.marketStartTime) - moment(b.marketStartTime);
  //   //     });
  //   //     setMatch(result.data);
  //   //     if (newrefresh) {
  //   //       setnewrefresh(false);
  //   //     }
  //   //
  //   //   })
  //   //   .catch(error => console.log(error));
  //
  //   Axios.get(
  //     `https://liveapi247.live/api8/checktv`)
  //
  //     .then(result => {
  //
  //       settv(result.data);
  //     })
  //     .catch(error => console.log(error));
  //
  //   Axios.get(
  //     `https://liveapi247.live/api8/checkbookmaker`)
  //
  //     .then(result => {
  //
  //       setbookmaker(result.data);
  //     })
  //     .catch(error => console.log(error));
  //
  //   // Axios.get(
  //   //   `https://liveapi247.live/api8/fancy`)
  //   //
  //   //   .then(result => {
  //   //
  //   //     setFancy(result.data);
  //   //     if (newrefresh) {
  //   //       setnewrefresh(false);
  //   //     }
  //   //
  //   //   })
  //   //
  //   //
  //   //   .catch(error => console.log(error));
  //
  //
  //
  //
  // }, []);


  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");
    if (props.isLoggedIn == true) {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPIN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < match.length; i++) {
        if (!document.getElementById(MKPIN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off";
        }
      }

    }


  }, [match, cricketUpdate]);

  const Multimarketpin = (index, event_id) => {
    if (props.isLoggedIn === false) {
      props.checkShowLogin(true)
    }
    else {
      var list = JSON.parse(localStorage.getItem("multi") || "[]");
      let ctr = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i] == event_id) {
          let j = i + 1;
          ctr = 1;
          for (; i < list.length; j++, i++) {
            list[i] = list[j];
          }
          list.splice([i - 1]);
        }
      }
      if (ctr == 0) {
        list.push(event_id);
      }
      localStorage.setItem("multi", JSON.stringify(list));
      setcricketUpdate(!cricketUpdate);

      // console.log('2', list);
      //  localStorage.clear();

    }
  }





  const matchMapData = (mathData)=>mathData.map(function (item, id) {
    var showColor = false;
    var showFancy = false;
    var isFancy = false;
    var showtv = false;
    var isbookmaker = false;


    var matchTime;
    var today = moment();
    var tommorow = moment().add(1, 'day');

    if (moment(item.marketStartTime).isSame(today, 'day')) {
      matchTime = moment(item.marketStartTime).format('LT');
    } else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
      matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');
    } else {
      matchTime = item.marketStartTime;
    }


    if (fancy && fancy.includes(item.eventId)) {
      isFancy = true;

    }
    if (tv && tv.includes(item.eventId)) {
      showtv = true;
    }
    if (bookmaker && bookmaker.includes(item.eventId)) {
      isbookmaker = true;

    }

    if (moment().diff(moment(item.marketStartTime), 'minutes') >= 0) {
      showColor = true;
      showFancy = true;
    }

    return (
        <div  key={id}>

          <li  id="highlightEvent_-10020846"
              className={`inplay${showColor ? "-on" : "-off"}`}>
            <Link  to={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`}
                  id="eventName" onClick={() => {
              props.changeState(true);
            }}>
              <dl >
                <dt >
                  {moment() >= moment(item.marketStartTime) &&
                      <span  id="streamingIcon" className="game-live"
                            style={{display: "flex"}}>game-live</span>}

                  {isFancy && <span  id="fancyBetIcon"
                                    className={`game-fancy ${showFancy ? "in-play" : "no-play"}`}
                                    style={{display: "flex"}}><pre>in-play</pre>Fancy</span>}
                  {isbookmaker &&
                      <span id="bookMakerIcon"
                            className={`game-bookmaker ${showColor ? "in-play" : "no-play"}`}
                            style={{display: "flex"}}><pre>in-play</pre>BookMaker</span>}
                  {moment() >= moment(item.marketStartTime) &&
                      <span id="sportsBookIcon_2" class="game-sportsbook" style={{display: "flex"}}>Premium Cricket</span>}

                  {moment() >= moment(item.marketStartTime) &&
                      <span id="dateTimeInfo"  className="time">In-Play</span>}
                  {moment() < moment(item.marketStartTime) &&
                      <span  id="dateTimeInfo" className="time">{matchTime}</span>}

                </dt>
                <dd>
                  {item.eventName} </dd>
              </dl>
            </Link>
            <a id={MKPIN + id}  className={`pin-off`}
               onClick={() => Multimarketpin(id, item.eventId)}></a>
          </li>
        </div>
    )
  })

  return (
      <>
        {match && <div>
          {props.isByCompetion ?
              Object.keys(competionDataList).map((competionName)=>(
                  <>
                    <h4
                        style={{
                          "display": "flex",
                          "alignItems": "center",
                          "textIndent": "2.6666666667vw",
                          "background": "#eff3f5",
                          "borderTop": "1px solid #bfcad1",
                          "borderBottom": "1px solid #bfcad1",
                          "height": "8.5333333333vw"
                        }}
                    >{competionName}</h4>
                    {matchMapData(competionDataList[competionName])}
                  </>
              ))
              : matchMapData(match)}
        </div>}
      </>
  )
}
