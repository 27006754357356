import axios from 'axios';
import {sportsKeyByName} from '../../utils/constant';
import {inPlayDataFilter, todayDataFilter, tomorrowDataFilter} from "../../utils/sportsHelper";
import moment from 'moment'
require('moment-timezone');

const baseApi1 = axios.create({
  baseURL: 'https://liveapi247.live',
});

export const getGameBalanceApiRq = async ({
  providercode,
  username
}) => {
  const response = await baseApi1.post('api8/getBalance', {
    providercode,
    username
  });
  return response;
};

export const refreshBalanceApiRq = async ({
  id,
  token
}) => {
  const response = await baseApi1.post('api1/refresh', {
    id,
    token,
  });
  return response;
};

export const enterCasinoTransactionApiRq = async ({
  username, 
  transferType, 
  providercode,
  amount,
  token,
}) => {
  const type = transferType === 'd' ? "0" : transferType === 'w' ? "1" : null;
  const response = await baseApi1.post('api8/enterCasinoTransaction_2', {
    username, 
    type, 
    providercode,
    amount,
    token,
  });
  return response;
};


export const getSportsDataApiRq = async () => {
  const response = await axios.all([
    baseApi1.get('api8/cricketData'),
    baseApi1.get('api8/soccerData'),
    baseApi1.get('api8/tennisData'),
    baseApi1.get('api8/basketballData'),
  ]);
  let sportsData = {
    [sportsKeyByName.CRICKET]: [],
    [sportsKeyByName.SOCCER]: [],
    [sportsKeyByName.TENNIS]: [],
    [sportsKeyByName.BASKET_BALL]: [],
  };
  response.map(({ data, status })=> {
    if(status === 200) {
      const filterDataBySportsName = (sportsName) => data.filter(i=> i.sportId == sportsName);

      if(filterDataBySportsName(sportsKeyByName.CRICKET).length !== 0){
        sportsData[sportsKeyByName.CRICKET] = filterDataBySportsName(sportsKeyByName.CRICKET) || [];
      }
      if(filterDataBySportsName(sportsKeyByName.SOCCER).length !== 0){
        sportsData[sportsKeyByName.SOCCER] = filterDataBySportsName(sportsKeyByName.SOCCER) || [];
      }
      if(filterDataBySportsName(sportsKeyByName.TENNIS).length !== 0){
        sportsData[sportsKeyByName.TENNIS] = filterDataBySportsName(sportsKeyByName.TENNIS) || [];
      }
      if(filterDataBySportsName(sportsKeyByName.BASKET_BALL).length !== 0){
        sportsData[sportsKeyByName.BASKET_BALL] = filterDataBySportsName(sportsKeyByName.BASKET_BALL) || [];
      }

    }
  })

  return sportsData;
};


export const getAllEventsApiRq = async () => {
  const rsp = await baseApi1.get("/api8/getallevents");

  // let data = {
  //   inPlay: {},
  //   today: {},
  //   tomorrow: {},
  //   all: {}
  // };

  let data = {
    1: [],
    2: [],
    4: [],
  };

  if(rsp.data && rsp.status === 200) {
    const events = rsp.data;

    data = events;
    // console.log('todayDataFilter(events[sportsKeyByName.CRICKET])',todayDataFilter(events[sportsKeyByName.CRICKET]))


    // data.inPlay =  {
    //   cricket: inPlayDataFilter(events[sportsKeyByName.CRICKET]),
    //   soccer: inPlayDataFilter(events[sportsKeyByName.SOCCER]),
    //   tennis: inPlayDataFilter(events[sportsKeyByName.TENNIS]),
    //   basketBall: []
    // }

    // console.log('todayDataFilter(events[sportsKeyByName.CRICKET])',todayDataFilter(events[sportsKeyByName.CRICKET]))


    // let arr = events[sportsKeyByName.CRICKET] || [];
    //
    // arr.map(item => {
    //   item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
    //   return item;
    // })
    // arr.sort(function (a, b) {
    //
    //   return moment(a.marketStartTime) - moment(b.marketStartTime);
    // });
    // let obj = [];
    //
    // arr.map((item) => {
    //
    //   var str = item.marketStartTime;
    //   var res = str.slice(11, 16);
    //   var today = moment();
    //   var thatDay = moment(item.marketStartTime);
    //
    //   if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
    //     obj.push(item);
    //   }
    //
    // });



    // console.log('obj',obj)

    // data.today =  {
    //   cricket: events[sportsKeyByName.CRICKET],
    //   soccer: events[sportsKeyByName.SOCCER],
    //   tennis: events[sportsKeyByName.TENNIS],
    //   basketBall: []
    // }

    // data.tomorrow =  {
    //   cricket: tomorrowDataFilter(events[sportsKeyByName.CRICKET]),
    //   soccer: tomorrowDataFilter(events[sportsKeyByName.SOCCER]),
    //   tennis: tomorrowDataFilter(events[sportsKeyByName.TENNIS]),
    //   basketBall: []
    // }


  }

  return data;
};


export const getCheckTvApiRq = async () => await baseApi1.get('api8/checktv');
export const getCheckBookmakerApiRq = async () => await baseApi1.get('api8/checkbookmaker');
export const getCheckFancyApiRq = async () => await baseApi1.get('api8/fancy');
export const getCheckScoreApiRq = async () => await baseApi1.get('api8/getskyScoreId');

export const getSportsCountApiRq = async () => await baseApi1.get('api1/eventCounts');

export const getBannerApiRq = async () => await baseApi1.post(
    'api8/getBanner',
    {
      siteName: 'VELLKIADMIN'
    }
);

export const getNewsApiRq = async (payload = {}) => await baseApi1.post(
    'api8/news',
    payload
);