import React, { useEffect, useState } from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import Cookies from 'universal-cookie';
import {getCasinoData, getCasinoObj} from '../casino-utils';
import queryString from 'query-string';
import LoadingOverlay from "./components/loading-overlay/loading-overlay";
import Footerline from '../images/sprite/Footer-line.svg'
import Androidicon from '../images/sprite/Android-icon.svg'
import Appleicon from '../images/sprite/Appleicon.svg'
import criclogo from '../images/headerlogo.png'
import {useBannerData, useSportsCountData} from "../serives/react-query/api-hooks";
import {getRqData} from "../serives/react-query/rq-helper";
import {RQ_KEY} from "../serives/react-query/constant";
import MainNav from './MainNav'
import ambassador from "../images/ambassador.png";
import whatsAppIcon from "../images/other/whatsappicon.gif";
import youtubeIcon from "../images/other/icons_youtube.png";
// import DepositBanner from '../mobilecomponent/DepositBanner/DepositBanner'
import depositico from  '../images/sprite/deposit-ico.png'

import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { RiTelegramLine } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

import { FaHotjar } from "react-icons/fa";
import { MdOutlineSportsSoccer } from "react-icons/md";
import { MdOutlineCasino } from "react-icons/md";
import { CgLivePhoto } from "react-icons/cg";
import { PiSpinnerBallDuotone } from "react-icons/pi";
import { IoRocketOutline } from "react-icons/io5";
import { TiFlashOutline } from "react-icons/ti";
import { FaCircleDollarToSlot } from "react-icons/fa6";
import { GiCircleClaws } from "react-icons/gi";
import { IoFishOutline } from "react-icons/io5";
import { LiaHorseHeadSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";
import { GiTabletopPlayers } from "react-icons/gi";
import Providercarousel from '../provider-carousel/Providercarousel';
import Carousel from './Carousel';
import Link from 'react-router-dom/Link';




const cookies = new Cookies();
export default function Home(props) {
      const getBlance =  getRqData(RQ_KEY.STORE_USER_DATA);
      const agentPhoneNumber = getBlance?.data?.agentPhoneNumber;

    // const [countCricket, setCountCricket] = useState(0);
    // const [countSoccer, setCountSoccer] = useState(0);
    // const [countTennis, setCountTennis] = useState(0);
    // const [countBasketBall, setCountBasketBall] = useState(0);
    const [loading, setloading] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const [casinoWait, setcasinoWait] = useState(false);

    const [activeTab, setActiveTab] = useState('Hot');

    const location = useLocation();
    const history = useHistory();

    // get Banner data
    let getSportsCountData = getRqData(RQ_KEY.STORE_SPORTS_COUNT_DATA);

    // enabled is on mount data get
    const { data } = useSportsCountData({
        // enabled: !getBannerData,
        select: (data) => data.data
    });
    let count = {};
    if(getSportsCountData && getSportsCountData.data && getSportsCountData.data.inPlay){
        count = getSportsCountData.data.inPlay;
    }





    // function changecount(cri, socc, tenn, basket) {
    //
    //     setCountCricket(cri);
    //     setCountSoccer(socc);
    //     setCountTennis(tenn);
    //     setCountBasketBall(basket)
    // }


    // useEffect(() => {
    //     axios.all([
    //         axios.get('https://liveapi247.live/api8/cricketData'),
    //         axios.get('https://liveapi247.live/api8/soccerData'),
    //         axios.get('https://liveapi247.live/api8/tennisData'),
    //         axios.get('https://liveapi247.live/api8/basketballData')
    //     ])
    //         .then(axios.spread((obj1, obj2, obj3, obj4) => {
    //
    //
    //             var count1 = 0;
    //             var count2 = 0;
    //             var count3 = 0;
    //             var count4 = 0;
    //
    //             obj1.data.map(item => {
    //                 item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
    //                 return item;
    //             })
    //
    //             obj2.data.map(item => {
    //                 item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
    //                 return item;
    //             })
    //             obj3.data.map(item => {
    //                 item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
    //                 return item;
    //             })
    //             obj4.data.map(item => {
    //                 item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
    //                 return item;
    //             })
    //
    //             obj1.data.map((item) => {
    //                 if (moment() >= moment(item.marketStartTime)) {
    //                     count1 = count1 + 1;
    //                 }
    //             });
    //             obj2.data.map((item) => {
    //                 if (moment() >= moment(item.marketStartTime)) {
    //                     count2 = count2 + 1;
    //                 }
    //             });
    //             obj3.data.map((item) => {
    //                 if (moment() >= moment(item.marketStartTime)) {
    //                     count3 = count3 + 1;
    //                 }
    //             });
    //             obj4.data.map((item) => {
    //                 if (moment() >= moment(item.marketStartTime)) {
    //                     count4 = count4 + 1;
    //                 }
    //             });
    //             setrefresh(false);
    //             setTimeout(() => { setloading(false); }, 5000);
    //             changecount(count1, count2, count3, count4);
    //
    //
    //
    //         }));
    // }, [])

    // const handleRefresh = () => {
    //     setloading(true);
    // }

    const homePageCasino = ['Go Rush', 'Crash Bonus', 'Limbu',  'sicbo', 'Super Ace', '7-Up-7-Down', 'Money Comming'];


    const openCasino = (navKey) => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj(navKey))}`) : props.openLogin();

    return (
        <React.Fragment>
            <LoadingOverlay
                show={(props.casinoWait || refresh === true)}
            />

            <div className="mob-main-content">
                <div style={{
                    background:"#000",
                    width: "100%",
                    "display": "flex",
                    "alignItems": "center",
                    "gap": "9px",
                    "overflowX": "scroll",
                    "scrollbarWidth": "none",
                    padding: '5px',
                }}>
                    {[
                        {
                            title: 'Hot',
                            icon: FaHotjar,
                            onClick: ()=> null,
                        },
                        {
                            title: 'Sports',
                            icon: MdOutlineSportsSoccer,
                            onClick: ()=> history.push('/d/dash/sports')
                        },
                        {
                            title: 'Casino',
                            icon: MdOutlineCasino,
                            onClick: ()=> openCasino('JILI')
                        },
                        {
                            title: 'Live',
                            icon: CgLivePhoto,
                            onClick: ()=> null
                        },
                        {
                            title: 'Roulette',
                            icon: PiSpinnerBallDuotone,
                            onClick: ()=> null
                        },
                        {
                            title: 'Evolution',
                            icon: IoRocketOutline,
                            onClick: ()=> null
                        },
                        {
                            title: 'EZUGI',
                            icon: TiFlashOutline,
                            onClick: ()=> null
                        },
                        {
                            title: 'Slots',
                            icon: FaCircleDollarToSlot,
                            onClick: ()=> null
                        },
                        {
                            title: 'Table',
                            icon: GiTabletopPlayers,
                            onClick: ()=> null
                        },
                        {
                            title: 'Bingo',
                            icon: GiCircleClaws,
                            onClick: ()=> null
                        },
                        {
                            title: 'Fishing',
                            icon: IoFishOutline,
                            onClick: ()=> null
                        },
                        {
                            title: 'Horse',
                            icon: LiaHorseHeadSolid,
                            onClick: ()=> null
                        },
                        {
                            title: 'Lottery',
                            icon: GiTakeMyMoney,
                            onClick: ()=> null
                        },
                    ].map((item, index) => {

                        return (
                            <>
                            </>
                            // <div
                            //     key={'nav-icon' + index}
                            //     className={`home-navigator ${(item.title === activeTab) ? 'active' : ''}`}
                            //     style={{ marginRight: item.title === 'Lottery' ? '15px' : 0, color: 'black',}}
                            //     /*to={item.link}*/
                            //     onClick={()=> {
                            //         setActiveTab(item.title);
                            //         item.onClick();
                            //     }}
                            // >
                            //     <div
                            //         className="box"
                            //         style={{
                            //             borderRadius: '8px',
                            //             width: '55px',
                            //             height: '55px',
                            //             display: 'flex',
                            //             justifyContent: 'center',
                            //             alignItems: 'center',
                            //             color: '#fff',
                            //         }}>
                            //         <item.icon size="28px"/>
                            //     </div>
                            //     <span style={{ textAlign: 'center', display: 'block', marginTop: '3px'}}>{item.title}</span>
                            // </div>
                        )
                    })}
                </div>
              
                {props.isLoggedIn === true &&  <div className="quick-wallet-dropdown">
                   
                    <Link style={{color:'#ffff'}} to="/d/dash/deposit"> <div className="quick-wallet-dropdown__button quick-wallet-button quick-wallet-button--primary mcd-button clickable deposit-button">
       {/* <img src={depositico} alt="" /> */}
     Deposit
        <div className="processing-mask"></div>
      </div></Link>  
      <Link to="/d/dash/withdraw">  <div className="quick-wallet-dropdown__button quick-wallet-button quick-wallet-button--secondary mcd-button clickable">
        Withdrawal
        <div className="processing-mask"></div>
      </div></Link>
     
   <Link  to="/d/dash/dw-history">
   <div className="quick-wallet-dropdown__button quick-wallet-button quick-wallet-button--primary mcd-button clickable">
        D/W history
        <div className="processing-mask"></div>
      </div></Link>  
  
    </div>}
                <MainNav isLoggedIn={props.isLoggedIn} openLogin={props.openLogin} />
                {/*<Providercarousel/>*/}
                <Carousel/>
                {/* <DepositBanner/> */}
                {/* <div className="casinozone">
                    {homePageCasino.map((casinoTitle, index) => {

                        const casinoObj = getCasinoData(casinoTitle);

                        return (
                            <CasinoCard
                                key={casinoTitle + index}
                                isLoggedIn={props.isLoggedIn}
                                navKey={casinoObj?.title}
                                title={casinoObj?.title}
                                img={casinoObj?.imgSrc}
                                openLogin={props.openLogin}
                                history={history}
                                style={{}}
                            />
                        )
                    })}
                </div> */}
                {/* <img
                
                    src={ambassador}
                    style={{
                        width: '100%',
                        height: 'auto',
                        background:"#141515"
                    }}
                /> */}
                <section className="mobile_footer_section pb-[80px]">
  <div className="bg-seventhBg py-2 relative">
    <img style={{width:"100%"}} src={Footerline} alt="" />
    <div className="betji_logo">
      <img style={{    maxWidth: '100%',height: 'auto'}} src={criclogo}alt="" />
    </div>
  </div>
  
  <div className="bg-black p-5">
    <div className="bg-seventhBg rounded-xl p-3 border-2 border-[#563939] border-bg-footer">
      <h5 className="text-base font-bold text-center">Disclaimer</h5>
      <p className="text-sm text-center mt-3">
        Although the current score, time elapsed, video and other data provided on this site is sourced from "live" feeds provided by third parties, you should be aware that this data may be subject to a time delay and/or be inaccurate. Please also be aware that other Haboom customers may have access to data that is faster and/or more accurate than the data shown on the Haboom site. If you rely on this data to place bets, you do so entirely at your own risk. Haboom provides this data as is with no warranty as to the accuracy, completeness or timeliness of such data and accepts no responsibility for any loss (direct or indirect) suffered by you as a result of your reliance on it.
      </p>
    </div>
  </div>

  <div className="bg-seventhBg flex justify-center px-3 py-6">
    <button className="border-[#D41967] bg-footer-transprant border-2 rounded-3xl p-2 m-1">
      <div className="flex items-center">
        <div className="w-[25px] mr-2">
          <img style={{    maxWidth: '100%',height: 'auto'}} src={Androidicon} alt="" />
        </div>
        <span className="font-bold text-sm whitespace-nowrap">Download Now</span>
      </div>
    </button>
    <button className="border-[#D41967] bg-footer-transprant border-2 rounded-3xl p-2 m-1">
      <div className="flex items-center">
        <div className="w-[25px] mr-2">
          <img style={{    maxWidth: '100%',height: 'auto'}} src={Appleicon} alt="" />
        </div>
        <span className="font-bold text-sm whitespace-nowrap">Coming soon</span>
      </div>
    </button>
  </div>

  <div>
    <p className="text-center text-sm sm:text-[18px] py-4 font-semibold">TOP PROVIDERS</p>
  </div>

  <div className="p-5">
    <p className="text-center font-semibold p-2 text-sm sm:text-[18px]">PAYMENT METHODS</p>
    <div className="grid grid-rows-4 grid-cols-3 gap-4">
      {[...Array(15)].map((_, i) => (
        <div key={i} className="flex items-center justify-center">
          <img className="w-[100px]" src={`https://betji-app.s3.ap-south-1.amazonaws.com/Betji/homepage/footer/bankLogos/images/${['WvmfB0tX4b57EV4t5RgWV71h3g135hedVs7oPiXX', 'YPInMSYulwHtNEBpWOsTp8z8MfE0aoeldxKW1yyC', 'iZPI8GO2PhAVw3DQXeYd9huk90ahZLizHx5eX5r2', 'eHV5ntI9X3Ia0LekZIsAFUcnQIRONHHIwDCLSPHS', 'r0WSEHsTDRqey4EStceWprSBoLNiIwcfeH5Re79x', 'gMsrqFC8TVveBLDccXbKeg1SEvHWMt9ODTSZIBLi', 'XnQMXxtaBNkwjk3TBZDq8wKvHGyGJR2086e6H45E', 'lIeImDYlyzQvbmlxhjgEwtus39aj6QjRuUyzZ8uN', 'fyTVGfDLtijmIqrSljOK0lqT30wJSK01P10hf9Cd', 'KXAvMvdKUbML3Jv9JqIlNhbIoSTuinlgTB7oo9Sx', 'BQX7wmNMhGWqeWj5Xs2dzXPUncdSl5Ba98nNnGUb', 'd1e4bDzA881pDVDu6Oc4qldt6OCCmp3XDBUPizCN', 'HnGw9rE7lYg4M04gO5IHbhLYjOhhPK7QXUTkKhRD', 'g055HPX4howRyWMSFJXpzEPQ1gFC3kBN1M0naQXO', '0LlZlJvsH16pIiFuEB2zkJ9OVxaQTfq6vn0ss3rW'][i]}.webp`} alt="" />
        </div>
      ))}
    </div>
  </div>

  <div className="flex justify-around p-3">
    <div className="cursor-pointer"><p className="text-sm">Help</p></div>
    <div className="cursor-pointer"><p className="text-sm">FAQs</p></div>
    <div className="cursor-pointer"><p className="text-sm">Affiliates</p></div>
  </div>

  <div className="flex justify-around p-3 mb-3">
    <div className="cursor-pointer"><p className="text-sm">Privacy & Policy</p></div>
    <div className="cursor-pointer"><p className="text-sm">Terms & Conditions</p></div>
  </div>

  <div className="my-3">
    <p className="text-center text-sm">
      <span>©&nbsp;</span>2025 cricbet247. All rights reserved.
    </p>
  </div>
</section>

                {/* <footer style={{padding: '25px 10px 60px', background: '#000', paddingTop: '30px'}}>
                    <div className="about-wrapper">
                        <h6 style={{
                            "lineHeight": "normal",
                            "fontSize": "1.3rem",
                            "color":"#fff",
                            "fontWeight": "700",
                            "marginBottom": "5px"
                        }}>
                            About cricbet247
                        </h6>
                        <p style={{"margin": "0 0 -8px", "textAlign": "justify", "lineHeight": "30px",color:"#fff"}}>cricbet247 was
                            established in 2017 and&nbsp;operated under a Curacao
                            gaming license&nbsp;with more than 2 million users. One of top&nbsp;Asia's most trusted and
                            leading online casinos and sports betting platforms. cricbet247 offers a wide selection of
                            slot games, live&nbsp;casinos, lotteries, sportsbooks, sports&nbsp;exchanges, and e-sports.
                            Offers you a variety&nbsp;of secure and easy payment methods along&nbsp;with 24-hour
                            friendly live customer support to&nbsp;ensure that any queries are dealt with
                            and&nbsp;resolved quickly.</p>
                    </div>
                    <div style={{marginTop: "25px"}}>
                        <h6 style={{
                            "lineHeight": "normal",
                            "fontSize": "1.3rem",
                            "fontWeight": "700",
                            "marginBottom": "5px",
                            color:"#fff"
                        }}>
                            Payment Methods
                        </h6>
                        <div style={{"maxWidth": "415px", "display": "flex", "flexWrap": "wrap", gap: "5px",color:"#fff"}}>
                            {['footer-payment-bank.svg', 'footer-payment-bkash.svg', 'footer-payment-nagad.svg', 'footer-payment-upi.svg', 'footer-payment-imps.svg', 'footer-payment-paytm.svg', 'footer-payment-net-banking.svg'].map((img, index) => (
                                <img
                                    key={img + index}
                                    src={require('../images/payment/' + img)}
                                    style={{
                                        "width": "calc(20% - 4px)",
                                        "height": "50px",
                                        "objectFit": 'cover',
                                        borderRadius: '5px'
                                    }}
                                />
                            ))}
                        </div>
                    </div>

                    <div style={{marginTop: "25px"}}>
                        <h6 style={{
                            "lineHeight": "normal",
                            "fontSize": "1.3rem",
                            "color":"#fff",
                            "fontWeight": "700",
                            "marginBottom": "5px",
                        }}>
                            Follow Us
                        </h6>
                        <div style={{"maxWidth": "415px", "display": "flex", "flexWrap": "wrap", gap: "5px"}}>
                            {[
                                {icon: FaFacebook, bg: '#3b5998'},
                                {
                                    icon: FaInstagram,
                                    bg: 'radial-gradient(circle farthest-corner at 32% 106%,#ffe17d 0,#ffcd69 10%,#fa9137 28%,#eb4141 42%,transparent 82%),linear-gradient(135deg,#234bd7 12%,#c33cbe 58%)'
                                },
                                {icon: FaWhatsapp, bg: '#00b900'},
                                {icon: RiTelegramLine, bg: '#08c'},
                                {icon: FaXTwitter, bg: '#000'},
                                {icon: MdEmail, bg: '#425563'},
                            ].map((icon, index) => (
                                <div
                                    key={icon.bg + index}
                                    style={{
                                        borderRadius: '100%',
                                        background: icon.bg,
                                        width: '50px',
                                        height: '50px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    <icon.icon size="28px" color="white"/>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div style={{marginTop: "25px"}}>
                        <h6 style={{
                            "lineHeight": "normal",
                            color:"#fff",
                            "fontSize": "1.3rem",
                            "fontWeight": "700",
                            "marginBottom": "5px",
                        }}>
                            Licenses
                        </h6>
                        <div style={{"maxWidth": "415px", "display": "flex", "flexWrap": "wrap", gap: "5px"}}>

                            {['gc.f267a07.svg', 'age-r.svg'].map((img, index) => (
                                <img
                                    key={img + index}
                                    src={require('../images/other/' + img)}
                                    style={{
                                        "width": "calc(20% - 4px)",
                                        "height": "50px",
                                        "objectFit": 'cover',
                                        borderRadius: '5px'
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </footer> */}

                <div style={{position: 'fixed', right: 0, bottom: '4rem', zIndex: 100, display: 'grid'}}>
                    <a  href={props.isLoggedIn ? `https://api.whatsapp.com/send?phone=${agentPhoneNumber}` : '/'}
                   >
                        <img
                            src={whatsAppIcon}
                            style={{
                                width: "66px",
                                height: "66px",
                                objectFit: "cover",
                                marginLeft: "-5px",
                                marginBottom: "-10px"
                            }}
                        />
                    </a>
                    <a href="#">
                        <img
                            src={youtubeIcon}
                            style={{
                                width: "55px",
                                height: "55px",
                                objectFit: "cover",
                            }}
                        />
                    </a>
                </div>


            </div>

        </React.Fragment>
    )
}
