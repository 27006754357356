import React, {useEffect, useState} from 'react';
import SelectBank from "./components/select-bank";
import {checkStrVsStr, fixValue, objIsNotEmpty} from "./utils";
import { FaCopy } from "react-icons/fa6";
import {useCopyToClipboard} from "react-use";
import axios from "axios";
import Cookies from "universal-cookie";
import {toast} from "react-toastify";
import {getRqData} from "../serives/react-query/rq-helper";
import {RQ_KEY} from "../serives/react-query/constant";
import moment from "moment";
const cookies = new Cookies();

toast.configure();


const DwCard = ({ txId, date, type, bank, amount, status, remark})=> {
    const statusColorGreen = checkStrVsStr(status, ['Completed', 'done', 'success']);
    const statusColorYellow = checkStrVsStr(status, ['Pending']);

    return (
        <div style={{ width: '100%', background: 'white', border: '1px solid #000', borderRadius: '5px', overflow: 'hidden', marginBottom: '5px'}}>
            <div style={{ background: "rgb(255, 184, 12)", padding: '5px'}}>
                {txId}
                <br/>
               <span style={{ opacity: 0.6}}>{date}</span>
            </div>
            <div style={{ display: 'flex', marginBottom: '2px'}}>
                <div style={{ background: '#F4F4F4', width: '50%', padding: '5px', textTransform: 'capitalize'}}>
                    Type <br/>
                    <span style={{ opacity: 0.6}}> {type}</span>
                </div>
                <div style={{ background: '#F4F4F4', width: '50%', padding: '5px'}}>
                    Bank <br/>
                    <span style={{ opacity: 0.6}}> {bank}</span>
                </div>
            </div>
            <div style={{ display: 'flex', marginBottom: '2px'}}>
                <div style={{ background: '#F4F4F4', width: '50%', padding: '5px',}}>
                    Amount <br/>
                    <span style={{ opacity: 0.6}}> {amount}</span>
                </div>
                <div style={{ background: '#F4F4F4', width: '50%', padding: '5px',}}>
                    Status <br/>
                    <span style={{ opacity: 0.6, fontWeight: "bold", color: statusColorYellow ? "rgb(255, 184, 12)" : statusColorGreen ? "green" : "red" }}>{status}</span>
                </div>
            </div>
            <div style={{  padding: '5px'}}>
                Remark: <span style={{ opacity: 0.6}}>{remark}</span>
            </div>
        </div>
    )
}


const DwHistory = () => {
    const [dwData, setDwData] = useState([]);

    const getDwHistoryData = async ()=> {
        let token = cookies.get('token');
        let clientId = cookies.get('id');
        // if(clientId){
        //     clientId = clientId.toUpperCase()
        // }
        const {data, status} = await axios.post('https://liveapi247.live/apiv9/getmytransactions', {
            clientId,
            token
        })
        if(status === 200 && data.status){
            setDwData(data.data);
        }
    }

    useEffect(() => {
        getDwHistoryData().catch();
    }, []);


    return (
        <>
            {/* <div style={{"alignItems": "center", "borderBottom": "2px solid rgb(255, 184, 12)", "padding": "10px 5px"}}>
                <div style={{"color": "rgb(255, 184, 12)", "fontSize": "1.3rem", "fontWeight": "700", "textAlign": "center"}}>D/W History</div>
            </div> */}

            <div style={{"marginBottom":"35px","height":"136vw","background":"#000","overflowY":"auto","padding":"13px"}}>
                {dwData.length > 0 ?  dwData.map((item, index) => (
                    <DwCard
                        txId={item?.txnid}
                        date={moment(item?.createdat).format("DD-MM-YYYY HH:mm:ss")}
                        type={item?.transactionType}
                        amount={item?.amount}
                        status={!item.isverified ? "Pending" : (item.status && item.isverified) ? "Completed" : "Failed"}
                        remark="-"
                        bank={item?.paymentmethod}
                    />
                )) : (
                    <div id="noMultiMarkets" class="no-data" style={{background:"rgba(240, 236, 225, 0.19)"}}><h3>There are currently no any deposit data</h3><p> Please make deposit first</p></div>
                )}
            </div>

        </>
    )
}
export default DwHistory;