import React, { useState, useEffect } from "react";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../images/sprite/loading40.gif'
import Transparent from '../images/sprite/transparent.gif'
// import calender from '../images/sprite/calender.png'
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";

import queryString from 'query-string';

import { toast } from 'react-toastify'
import { useHistory, useLocation } from "react-router-dom";
import { getCasinoObj } from "../casino-utils";


window.pnlView = 1;
window.daypnl = 3;
toast.configure()

export default function ProfitLoss(props) {

	//const date = new Date().toLocaleDateString();
	const [time, setDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
	const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
	const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().toDate());
	const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
	const [sTime, setsTime] = useState('09:00')
	const [eTime, seteTime] = useState('08:59')
	// const [select, setselect] = useState(0)
	const [profitLoss, setprofitLoss] = useState([])
	const [click, setclick] = useState(-1);
	const [eventType, seteventType] = useState('0');
	const [eventProfitLoss, seteventProfitLoss] = useState([])
	const [netpl, setnetpl] = useState(0);

	// hook
	const history = useHistory();
	const { search } = useLocation();
	// console.log(search); // "?filter=top&origin=im"
	const searchParams = queryString.parse(search)
	const select = searchParams.select;
	/*console.log('select', select)*/


	const changeStartDate = (val) => {
		setsDate(val);
	}
	const changeEndDate = (val) => {
		seteDate(val);
	}
	const changeStartTime = (val) => {
		setsTime(val);
	}
	const changeEndTime = (val) => {
		seteTime(val);
	}

	useEffect(() => {
		var timerID = setInterval(() => tick(), 1000);
		return function cleanup() {
			clearInterval(timerID);
		};
	}, []);

	function tick() {
		setDate(moment().format('YYYY-MM-DD HH:mm:ss'));
	}


	const changeEvent = (e) => {
		var selectBox = document.getElementById("sportsevent");
		var selectedValue = selectBox.options[selectBox.selectedIndex].value;

		if (selectedValue == 0) {
			seteventType('0');
		}
		else if (selectedValue == 1) {
			seteventType('1');
		}
		else if (selectedValue == 2) {
			seteventType('2');
		}
		else if (selectedValue == 4) {
			seteventType('4');
		}

		window.pnlView = 1;
		getpnl(selectedValue);



	}

	const getpnl = (valEventtype) => {

		var start = '';
		var end = '';

		if (window.daypnl === 3) {
			start = sDate + ' ' + sTime + ':00';
			end = eDate + ' ' + eTime + ':00';
		}
		else if (window.daypnl === 1) {
			var now = moment();
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = now.endOf('day').format("YYYY-MM-DD HH:mm:ss");

		}
		else if (window.daypnl === 2) {
			var now = moment().subtract(1, 'days');
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}

		let gameType = null;
		if (select == 2) {
			gameType = "Casino";
		} else if (select == 0) {
			gameType = "Exchange";
		} else if (select == 3) {
			gameType = "premium";
		}
		axios.post('https://liveapi247.live/api8/newprofitloss', {
			id: props.userid,
			token: props.utoken,
			startDate: start,
			endDate: end,
			eventType: valEventtype,
			view: window.pnlView,
			gameType,
			offSet: 0,
		}).then(result => {
			if (result.data.length == 0) {
				toast.warn('You have no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
				if (eventType != '0') {
					seteventType('0');
				}
			}

			if (result.status === 200) {
				setprofitLoss(result.data);
				// console.log(result.data);
				var arr = [];
				var netSum = 0;
				result.data.map((item) => {
					arr.push(item.eventId);
					netSum = netSum + parseFloat(item.pl);
				});
				seteventProfitLoss(arr);
				setnetpl(netSum.toFixed(2));
				if (arr.length === 0) {
					return;
				}

				let apiUrl = 'https://liveapi247.live/api8/eventprofitloss';
				if (select == 2) {
					apiUrl = 'https://liveapi247.live/api8/casinopnlbetdetails'
				} else if (select == 3) {
					apiUrl = 'https://liveapi247.live/api8/premiumprofitlossdetail'
				}
				axios.post(apiUrl, {
					id: props.userid,
					token: props.utoken,
					eventId: arr,
				})
					.then(result => {
						if (result.status === 200) {
							seteventProfitLoss(result.data);
						}


					})
					.catch(e => {
						//setIsError(true);
					})


			}
		}

		).catch(e => {
			//setIsError(true);
		});

	}


	const handleSlip = (index) => {
		if (click === index) {
			setclick(-1);
		}
		else {
			setclick(index);
		}
	}

	// clear table data
	useEffect(() => {
		setprofitLoss([]);
		seteventProfitLoss([]);
	}, [select])


	return (

		<div>
			<div _ngcontent-njs-c61 class="col-left">
				<div _ngcontent-njs-c61 class="sub_path">
					<div _ngcontent-njs-c61 class="path">

						<ul _ngcontent-njs-c61  >
							<li _ngcontent-njs-c61 class="path-last"><a >My Account</a></li>
						</ul>
					</div>
					<ul _ngcontent-njs-c61 id="sports-path-popup" class="sports-path-popup">
					</ul>
					<ul _ngcontent-njs-c61 class="sports-side-menu-list">
						<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="sub_menu_detail" href="/d/dash/myprofile">My Profile</a></li>
						<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="sub_menu_summary" href="/d/dash/balance">Balance Overview</a></li>
						<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="sub_menu_accountCashStatement_log" href="/d/dash/AccountStatement">Account Statement
						</a></li>
						<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="sub_menu_my_bets" href="/d/dash/mybets" class="select">My Bets</a></li>
						<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="sub_menu_activity_log" href="/d/dash/activitylog">Activity Log</a></li>
						<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="sub_menu_activity_log" onClick={props.handlepassmodel} >Change password</a></li>
					</ul>
				</div>
			</div>
			<div _ngcontent-njs-c61 class="centar-content report">
				<div _ngcontent-njs-c61 id="message" class="message-content success">
					<a _ngcontent-njs-c61 class="close-button">Close</a>
					<p></p>
				</div>
				<div _ngcontent-njs-c61 id="message" class="message-content success">
					<a _ngcontent-njs-c61 class="close-button"  >Close</a>
					<p _ngcontent-njs-c61  >Your cash out was successful !</p>
				</div>
				<h2 _ngcontent-njs-c61  >My Bets</h2>
				<ul _ngcontent-njs-c61 style={{ display: 'flex', width: '55%' }} class="tab col3">
					<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 href="/d/dash/mybets"  >Current Bets</a></li>
					<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 href="/d/dash/bethistory" >Bets History</a></li>
					<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 href="/d/dash/ProfitLoss" class="select"  >Profit &amp; Loss</a></li>
					<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 href="/d/dash/casinoProfitLoss">Casino Report</a></li>
				</ul>
				<div _ngcontent-njs-c61 class="white-wrap">
					<h3 _ngcontent-njs-c61  >Profit &amp; Loss - Main wallet</h3>
					<ul _ngcontent-njs-c61 class="acc-info">
						<li _ngcontent-njs-c61 class="user">{props.userid}</li>
						<li _ngcontent-njs-c61 class="time">{time}</li>
					</ul>
					<ul _ngcontent-njs-c61 class="report-tab-wrap" >
						<li _ngcontent-njs-c61
							// onClick={() => { setselect(0) }}
							onClick={() => history.push('?select=0')}
							className={`report-tab ${(select == 0) ? "select" : "null"}`} id="reportType_exchange" data-reporttabtype="0">
							Exchange
						</li>
						<li _ngcontent-njs-c61
							// onClick={() => { setselect(2) }}
							onClick={() => history.push('?select=2')}
							className={`report-tab ${(select == 2) ? "select" : "null"}`} id="reportType_sportsBook" data-reporttabtype="2">
							Casino
						</li>
						<li _ngcontent-njs-c61
							// onClick={() => { setselect(3) }}
							onClick={() => history.push('?select=3')}
							className={`report-tab ${(select == 3) ? "select" : "null"}`} id="reportType_bookMaker" data-reporttabtype="3">
							Sportsbook
						</li>
						<li _ngcontent-njs-c61
							// onClick={() => { setselect(4) }}
							onClick={() => history.push('?select=4')}
							className={`report-tab ${(select == 4) ? "select" : "null"}`} id="reportType_bPoker" data-reporttabtype="4">
							BPoker
						</li>
						<li _ngcontent-njs-c61
							// onClick={() => { setselect(5) }}
							onClick={() => history.push('?select=5')}
							className={`report-tab ${(select == 5) ? "select" : "null"}`} id="reportType_binary" data-reporttabtype="5">
							Binary
						</li>
					</ul>
					<div _ngcontent-njs-c61 class="function-wrap">
						<ul _ngcontent-njs-c61 class="input-list">
							<li _ngcontent-njs-c61  ><label>Period</label></li>
							<li>

								<ul _ngcontent-njs-c61 class="input-list" style={{ display: 'inline-flex' }}>

									<DatePicker
										selectsStart
										dateFormat="yyyy-MM-dd"
										selected={startDate}
										placeholderText="YYYY-MM-DD"
										className="cal-input"
										onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
									/>
									<input _ngcontent-njs-c61 id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

									<DatePicker
										selectsEnd
										dateFormat="yyyy-MM-dd"
										placeholderText="YYYY-MM-DD"
										selected={endDate}
										onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
										className="cal-input"
									/>
									<input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


								</ul>


							</li>


						</ul>
						<ul _ngcontent-njs-c61 class="input-list">
							<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="today" onClick={() => { window.daypnl = 1; window.pnlView = 1; getpnl(eventType); }} class="btn">Just For Today</a></li>
							<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="yesterday" onClick={() => { window.daypnl = 2; window.pnlView = 1; getpnl(eventType); }} class="btn">From Yesterday</a></li>

							<li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="getPL" onClick={() => { window.daypnl = 3; window.pnlView = 1; getpnl(eventType); }} class="btn-send">Get P &amp; L</a></li>
						</ul>
					</div>
					{profitLoss.length == 0 && <div _ngcontent-njs-c61 id="noReportMessage"><p>Betting Profit &amp; Loss enables you to review the bets you have placed. <br />
						Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.</p>
						<p>Betting Profit &amp; Loss is available online for the past 2 months.</p></div>}
				</div>



				{profitLoss.length > 0 && <div id="report" data-report="profitAndLossReport">
					<ul _ngcontent-njs-c61 id="spotsUl" class="total-show">
						<li _ngcontent-njs-c61 id="totalPL">Total P/L: USD <span _ngcontent-njs-c61 className={`${netpl >= 0 ? "" : "red"}`}>{netpl >= 0 ? parseFloat(netpl).toFixed(2) : '(' + Math.abs(netpl).toFixed(2) + ')'}</span></li>
						<li _ngcontent-njs-c61 id="sumOfQuery" class="sports-switch">USD <span _ngcontent-njs-c61 className={`${netpl >= 0 ? "" : "red"}`}>{netpl >= 0 ? parseFloat(netpl).toFixed(2) : '(' + Math.abs(netpl).toFixed(2) + ')'}</span></li>
						<li _ngcontent-njs-c61 class="sports-switch">
							<select name="sports" id="sportsevent" onChange={(e) => { changeEvent(e); }}>
								<option _ngcontent-njs-c61 value="0" selected="selected">ALL</option><option value="1">SOCCER</option><option value="2">TENNIS</option><option value="4">CRICKET</option></select>
						</li>
					</ul>


					<table _ngcontent-njs-c61 id="reportTable" class="table01 table-pnl" style={{ display: 'table' }}>
						<tbody _ngcontent-njs-c61  >
							<tr _ngcontent-njs-c61  >
								<th _ngcontent-njs-c61 width="" class="align-L">Market
								</th>
								<th _ngcontent-njs-c61 width="15%">Start Time
								</th>
								<th _ngcontent-njs-c61 width="15%">Settled date
								</th>
								<th _ngcontent-njs-c61 width="18%">Profit/Loss
								</th>
							</tr>


							{profitLoss.map((item, index) => {
								var matchName;

								if (item.sportId == '4') {
									matchName = 'CRICKET';
								} else if (item.sportId == '1') {
									matchName = 'SOCCER';
								}
								else if (item.sportId == '2') {
									matchName = 'TENNIS';
								} else if (item.sportId == '6') {
									matchName = 'CASINO';
								}

								let th2 = 'Selection';
								let th3 = 'Odds'
								if (select == 2) {
									th2 = "Game type"
									th3 = "Platform"
								}


								return (

									<React.Fragment key={index}>
										<tr _ngcontent-njs-c61 id="summary0" style={{ display: 'table-row' }} >
											<td _ngcontent-njs-c61 id="title" class="align-L">{matchName}<img class="fromto" src={Transparent} /><strong>{item.eventName}</strong></td>
											<td _ngcontent-njs-c61 id="startTime">{item.startTime}</td>
											<td _ngcontent-njs-c61 id="settledDate">{item.settledDate}</td>
											<td _ngcontent-njs-c61  >
												<a id="pl0" className={`${click === index ? "expand-open" : "expand-close"}`} onClick={() => { handleSlip(index); }}><span className={`${item.pl >= 0 ? "" : "red"}`} >{item.pl >= 0 ? parseFloat(item.pl).toFixed(2) : '(' + Math.abs(item.pl).toFixed(2) + ')'}  </span></a>
											</td>
										</tr>

										{click === index && <tr id="detail0" class="expand" style={{ display: 'table-row' }}>
											<td _ngcontent-njs-c61 colspan="4">
												<img _ngcontent-njs-c61 class="expand-arrow-R" src={Transparent} />

												<table _ngcontent-njs-c61 class="table-commission">
													<tbody _ngcontent-njs-c61  ><tr _ngcontent-njs-c61  >
														<th _ngcontent-njs-c61 width="9%">Bet ID
														</th>
														<th _ngcontent-njs-c61 width="">{th2}
														</th>
														<th _ngcontent-njs-c61 width="9%">{th3}
														</th>
														<th _ngcontent-njs-c61 width="13%">Stake
														</th>
														<th _ngcontent-njs-c61 width="8%">Type
														</th>
														<th _ngcontent-njs-c61 width="16%">Placed
														</th>
														<th _ngcontent-njs-c61 width="23%">Profit/Loss
														</th>
													</tr>

														{eventProfitLoss.map((i, index2) => {
															let item2 = { type: '', ...i };
															// console.log(item2, 'allprofitlossdata');
															var playerName;
															var odds;
															var matchtype;
															var profit = '';
															let showProfitLossDetails = '0';

															if (item2.betType === 'match') {
																odds = parseFloat(parseFloat(item2.rate) + 1).toFixed(2);
																if (item2.teamName === 'A') {
																	playerName = item2.runnerName1;
																}
																else if (item2.teamName === 'B') {
																	playerName = item2.runnerName2;
																}
																else if (item2.teamName === 'T') {
																	playerName = 'The Draw';
																}
															}
															else if (item2.betType === 'fancy') {
																playerName = item2.runnerName;
																odds = item2.rate + '/' + parseFloat(item2.teamName * 100).toFixed(0);
															}

															if (item2.type === 'LAGAI') {
																matchtype = 'BACK';
															}
															else if (item2.type === 'KHAI') {
																matchtype = 'LAY';
															}
															else {
																matchtype = item2.type;
															}

															if (item2.betType == 'fancy' && item2.result) {
																if (item2.type == "YES") {
																	if (parseFloat(item2.rate) <= parseFloat(item2.result)) {
																		profit = parseFloat(item2.amount * item2.teamName).toFixed(2);
																	}
																	else profit = parseFloat(item2.amount * (-1)).toFixed(2);
																}
																else {
																	if (parseFloat(item2.rate) > parseFloat(item2.result)) {
																		profit = parseFloat(item2.amount).toFixed(2);
																	}
																	else profit = parseFloat((item2.amount * item2.teamName) * (-1)).toFixed(2);
																}
															}
															else if (item2.betType == 'match' && item2.winner) {
																if (item2.type == "LAGAI") {
																	if (item2.teamName == 'A') {
																		if (item2.winner == "A") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
																		else profit = parseFloat(item2.amount * (-1)).toFixed(2);
																	}
																	else if (item2.teamName == "B") {
																		if (item2.winner == "B") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
																		else profit = parseFloat(item2.amount * (-1)).toFixed(2);
																	}
																	else if (item2.teamName == "T") {
																		if (item2.winner == "T") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
																		else profit = parseFloat(item2.amount * (-1)).toFixed(2);
																	}
																}
																else if (item2.type == "KHAI") {
																	if (item2.teamName == 'A') {
																		if (item2.winner != "A") profit = parseFloat(item2.amount).toFixed(2);
																		else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
																	}
																	else if (item2.teamName == "B") {
																		if (item2.winner != "B") profit = parseFloat(item2.amount).toFixed(2);
																		else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
																	}
																	else if (item2.teamName == "T") {
																		if (item2.winner != "T") profit = parseFloat(item2.amount).toFixed(2);
																		else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
																	}
																}

																if ((item2.runnerId3 == null || item2.runnerId3 == '' || item2.runnerId3 == ' ') && item2.winner == "T") {
																	profit = 0;
																}
															}
															if (item2.eventType == 6) {
																// for casino
																profit = item2.winner;
																playerName = `${item2.eventName}/${item2.marketName}`;
																odds = item2.teamName
															} else if (select == 3) {
																console.log('select', select)
																odds = item2?.rate;
																playerName = item2?.runnerName
																profit = item2?.pl;
																showProfitLossDetails = item2?.status;
																item2.type = 'LAGAI';
																matchtype = 'BACK'
																// console.log('showProfitLossDetails', showProfitLossDetails)
															}

															return (
																<React.Fragment key={index2}>
																	{item.eventId === item2.eventId && <tr id="txTemplate" className={`${(index2 % 2) == 0 ? "even" : ""}`}>
																		<td _ngcontent-njs-c61 id="betID">{item2.id}</td>
																		<td _ngcontent-njs-c61 id="matchSelection">{playerName}</td>
																		<td _ngcontent-njs-c61 id="txOddsMatched">{odds}</td>
																		<td _ngcontent-njs-c61 id="txStake">{item2.amount}</td>
																		<td _ngcontent-njs-c61  ><span _ngcontent-njs-c61 id="matchType" className={`${item2.type === 'LAGAI' || item2.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span></td>
																		<td _ngcontent-njs-c61 id="placed">{item2.betTime}</td>
																		<td _ngcontent-njs-c61 id="txLiability" className={`${profit >= 0 ? "" : "red"}`}>
																			{showProfitLossDetails == '0' ? profit >= 0 ? profit : '(' + Math.abs(profit).toFixed(2) + ')' : ''}
																		</td>
																	</tr>}
																</React.Fragment>
															)
														})}


													</tbody>
												</table>

											</td>
										</tr>}
									</React.Fragment>
								)
							})}


						</tbody>
					</table>
					<p _ngcontent-njs-c61 class="table-other">Profit and Loss is shown net of commission.
					</p>
				</div>}


				<div>
					{profitLoss.length > 0 && <ul _ngcontent-njs-c61 id="pageNumberContent" class="pages">
						<li _ngcontent-njs-c61 id="prev"><a _ngcontent-njs-c61 onClick={() => { if (window.pnlView > 1) { window.pnlView = window.pnlView - 1; getpnl(eventType); } }} className={`${(window.pnlView > 1) ? "" : "disable"}`}>Prev</a></li>
						<li _ngcontent-njs-c61 id="pageNumber"><a _ngcontent-njs-c61 class="select" style={{ pointerEvents: ' none' }}>{window.pnlView}</a></li>
						<li _ngcontent-njs-c61 id="next"><a _ngcontent-njs-c61 onClick={() => { if (profitLoss.length >= 50) { window.pnlView = window.pnlView + 1; getpnl(eventType); } }} className={`${(profitLoss.length >= 50) ? "" : "disable"}`} >Next</a></li></ul>}

				</div>

			</div>

		</div>

	)
}
