import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel3D.css'; // Optional: for custom styles

const Carousel3D = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <div>
          <img src="https://img.b112j.com/upload/h5Announcement/image_176265.jpg" alt="Slide 1" />
        </div>
        <div>
          <img src="https://img.b112j.com/upload/h5Announcement/image_176265.jpg" alt="Slide 2" />
        </div>
        <div>
          <img src="https://img.b112j.com/upload/h5Announcement/image_176265.jpg" alt="Slide 3" />
        </div>
        <div>
          <img src="https://img.b112j.com/upload/h5Announcement/image_176265.jpg" alt="Slide 4" />
        </div>
        <div>
          <img src="https://img.b112j.com/upload/h5Announcement/image_176265.jpg" alt="Slide 5" />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel3D;