import React from 'react';
import queryString from "query-string";
import {getCasinoObj} from "../../../casino-utils";

const CasinoCell = ({ img, name, isLoggedIn, link, openLogin, history}) => (
    <div className="main-nav-games-grid-cell"  onClick={() => link ? isLoggedIn ? history.push(link) : openLogin() : null}>
        <div className="main-nav-games-grid-cell__icon">
            <img alt={img} src={require(`../../../images/home-casino-options/${img}`)} loading="lazy" />
        </div>
        <div className="main-nav-games-grid-cell__text" style={{ textTransform: 'capitalize'}}>
            {name}
        </div>
    </div>
)

export default CasinoCell;