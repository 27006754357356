import casinoData2 from './casino-data.json';
import queryString from "query-string";

export const casinoData = [
    {
        "id": 1,
        "imgSrc": "bacarat-01.png",
        "title": "AE Sexy",
        "category": [
            "live",
            "popular"
        ],
        "plateform": [
            "sexy"
        ],
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-001",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 2,
        "imgSrc": "ludo-bet.jpeg",
        "title": "Ludo Bet",
        "category": [
            "popular"
        ],
        "plateform": [
            "Ludo"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "LUDO-TABLE-001",
            "platform": "LUDO"
        }
    },
    {
        "id": 2934,
        "imgSrc": "jili_7up7down.png",
        "title": "7up7down",
        "category": [],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-011",
            "platform": "JILI"
        }
    },
    {
        "id": 3,
        "imgSrc": "venus.webp",
        "title": "VENUS",
        "category": [
            "live",
            "popular"
        ],
        "plateform": [
            "VENUS"
        ],
        "isLobby": true,
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "VN-LIVE-001",
            "platform": "VENUS"
        }
    },
    {
        "id": 2474,
        "imgSrc": "yesbingo.webp",
        "title": "Yes Bingo",
        "category": [
            "Lottery",
            "fishing",
            "slot"
        ],
        "plateform": [
            "YESBINGO"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "YESBINGO"
        }
    },
    {
        "id": 247,
        "imgSrc": "spade.webp",
        "title": "SPADE",
        "category": [
            "SP",
            "arcade",
            "Fishing",
            "slot"
        ],
        "plateform": [
            "SPADE",
            "SP"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "SPADE"
        }
    },
    {
        "id": 2433,
        "imgSrc": "pocketGamesSoft.png",
        "title": "Pocket Games Soft",
        "category": [],
        "plateform": [
            "PG"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "PG"
        }
    },
    {
        "id": 2434,
        "imgSrc": "pocketGamesSoft.png",
        "title": "FC",
        "category": [
            "arcade",
            "Fishing",
            "slot"
        ],
        "plateform": [
            "FC"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "FC"
        }
    },
    {
        "id": 2434,
        "imgSrc": "youlian-gaming-1.webp",
        "title": "YL",
        "category": [
            "arcade",
            "Fishing",
            "slot"
        ],
        "plateform": [
            "YL"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "YL"
        }
    },
    {
        "id": 242,
        "imgSrc": "jdb.webp",
        "title": "JDB",
        "category": [
            "Fishing",
            "slot"
        ],
        "plateform": [
            "JDB"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "JDB"
        }
    },
    {
        "id": 239,
        "imgSrc": "red-tiger.jpeg",
        "title": "Redtiger",
        "category": [
            "slot"
        ],
        "plateform": [
            "RT"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "RT"
        }
    },
    {
        "id": 19,
        "imgSrc": "jili-lobby.jpg",
        "title": "JILI",
        "category": [
            "slot",
            "table",
            "fishing",
            "lottery"
        ],
        "plateform": [
            "JILI"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "JILI"
        }
    },
    {
        "id": 634,
        "imgSrc": "playtec_banner.jpeg",
        "title": "playtech",
        "category": [
            "casino",
            "slot"
        ],
        "plateform": [
            "PT"
        ],
        "isLobby": true,
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "PT-LIVE-001",
            "platform": "PT"
        }
    },
    {
        "id": 60,
        "imgSrc": "pp.jpeg",
        "title": "pragmatics play",
        "category": [
            "casino",
            "slot"
        ],
        "plateform": [
            "PP"
        ],
        "isLobby": true,
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "PP-LIVE-001",
            "platform": "PP"
        }
    },
    {
        "id": 61,
        "imgSrc": "cockfight.webp",
        "title": "Cock Fight PHI",
        "category": [
            "casino"
        ],
        "plateform": [
            "SV388"
        ],
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "SV-LIVE-001",
            "platform": "SV388"
        }
    },
    {
        "id": 62,
        "imgSrc": "Horse Racing.webp",
        "title": "HORSE BOOK",
        "category": [
            "casino",
            "sports"
        ],
        "plateform": [
            "HORSEBOOK"
        ],
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "HRB-LIVE-001",
            "platform": "HORSEBOOK"
        }
    },
    {
        "id": 64,
        "imgSrc": "banner_casino-half.png",
        "title": "Live Casino",
        "category": [
            "casino"
        ],
        "plateform": [],
        "isLobby": true,
        "gameInfo": {}
    },
    {
        "id": 62,
        "imgSrc": "gaming-evolution.jpg",
        "title": "EVOLUTION",
        "category": [
            "Table"
        ],
        "plateform": [
            "EVOLUTION"
        ],
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "EVOLUTION-LIVE-001",
            "platform": "EVOLUTION"
        }
    },
    {
        "id": 3,
        "imgSrc": "saba-sport.png",
        "title": "SABA",
        "category": [
            ""
        ],
        "plateform": [
            "SABA"
        ],
        "gameInfo": {
            "gameType": "VIRTUAL",
            "gameCode": "SABA-VIRTUAL-001",
            "platform": "SABA"
        }
    },
    {
        "id": 3,
        "imgSrc": "e1sports.jpeg",
        "title": "E1SPORT",
        "category": [
            "Egame",
            "arcade"
        ],
        "plateform": [
            "E1SPORT"
        ],
        "gameInfo": {
            "gameType": "ESPORTS",
            "gameCode": "E1-ESPORTS-001",
            "platform": "E1SPORT"
        }
    },
    {
        "id": 4,
        "imgSrc": "KM-TABLE-1.jpg",
        "title": "Cash Rocket",
        "category": [
            "Table",
            "popular",
            "Lottery"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-041",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 5,
        "imgSrc": "KM-TABLE-063.webp",
        "title": "Tongits",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-063",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 6,
        "imgSrc": "KM-TABLE-2.webp",
        "title": "Ludo",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-060",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 7,
        "imgSrc": "KM-TABLE-3.jpg",
        "title": "Marble Knockout",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-053",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 8,
        "imgSrc": "KM-TABLE-050.png",
        "title": "Color Game",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-050",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 9,
        "imgSrc": "KM-TABLE-5.jpg",
        "title": "Horse Racing",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-048",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 10,
        "imgSrc": "KM-TABLE-6.png",
        "title": "Hound Racing",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-055",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 12,
        "imgSrc": "KM-TABLE-7.png",
        "title": "KM Virtual Treadmill Racing",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-058",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 14,
        "imgSrc": "KM-TABLE-062.png",
        "title": "KM Virtual Animal Race",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-062",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 15,
        "imgSrc": "KM-TABLE-8.jpg",
        "title": "5 Card Poker",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-049",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 15,
        "imgSrc": "KM-TABLE-040.jpg",
        "title": "Plinko",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-040",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 16,
        "imgSrc": "KM-TABLE-10.jpg",
        "title": "Bonus Dice",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-043",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 17,
        "imgSrc": "KM-TABLE-11.jpg",
        "title": "Kingmaker Pok Deng",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-034",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 18,
        "imgSrc": "KM-TABLE-12.jpg",
        "title": "Monkey King Roulette",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-045",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 19,
        "imgSrc": "KM-TABLE-13.jpg",
        "title": "sicbo",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-015",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 20,
        "imgSrc": "KM-TABLE-028.jpg",
        "title": "7-Up-7-Down",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-028",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 21,
        "imgSrc": "KM-TABLE-036.jpg",
        "title": "Coin Toss",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-036",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 21,
        "imgSrc": "kingmaker_cardhilo.png",
        "title": "Card Hillo",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-037",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 21,
        "imgSrc": "KM-TABLE-039.jpg",
        "title": "32 Cards",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-039",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 22,
        "imgSrc": "KM-TABLE-18.jpg",
        "title": "Card Matka",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-022",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 23,
        "imgSrc": "KM-TABLE-19.jpg",
        "title": "Number Matka",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-021",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 24,
        "imgSrc": "KM-TABLE-20.jpg",
        "title": "Andar Bahar",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-032",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 25,
        "imgSrc": "KM-TABLE-21.jpg",
        "title": "Blackjack",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-038",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 26,
        "imgSrc": "KM-TABLE-22.jpg",
        "title": "Mine Sweeper",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-042",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 27,
        "imgSrc": "KM-TABLE-035.jpg",
        "title": "Bola Golek",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-035",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 28,
        "imgSrc": "KM-TABLE-24.jpg",
        "title": "Jhandi Munda",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-030",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 114,
        "imgSrc": "kingmaker-lobby.jpg",
        "title": "KINGMAKER",
        "category": [
            "Lottery",
            "table"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "isLobby": true,
        "gameInfo": {
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 29,
        "imgSrc": "JILI-TABLE-021.png",
        "title": "Ludo Quick",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-021",
            "platform": "JILI"
        }
    },
    {
        "id": 30,
        "imgSrc": "jili_Baccarat.png",
        "title": "Baccarat Jilli",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-014",
            "platform": "JILI"
        }
    },
    {
        "id": 31,
        "imgSrc": "jili_SicBo-Jili.png",
        "title": "Sicbo Jili",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-017",
            "platform": "JILI"
        }
    },
    {
        "id": 32,
        "imgSrc": "jili_teenpatti.jpeg",
        "title": "Teen Patti",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-001",
            "platform": "JILI"
        }
    },
    {
        "id": 33,
        "imgSrc": "JILI-TABLE-047.png",
        "title": "Crash Bonus",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-047",
            "platform": "JILI"
        }
    },
    {
        "id": 35,
        "imgSrc": "JILI-TABLE-048.png",
        "title": "Pool Rummy",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-048",
            "platform": "JILI"
        }
    },
    {
        "id": 36,
        "imgSrc": "JILI-TABLE-042.png",
        "title": "Mini Flush",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-042",
            "platform": "JILI"
        }
    },
    {
        "id": 37,
        "imgSrc": "JILI-TABLE-018.png",
        "title": "Super Bingo",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-018",
            "platform": "JILI"
        }
    },
    {
        "id": 38,
        "imgSrc": "JILI-TABLE-015.png",
        "title": "Fortune Bingo",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-015",
            "platform": "JILI"
        }
    },
    {
        "id": 40,
        "imgSrc": "JILI-TABLE-011.png",
        "title": "Fortune Bingo",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-011",
            "platform": "JILI"
        }
    },
    {
        "id": 41,
        "imgSrc": "JILI-TABLE-013.png",
        "title": "Callbreak quick",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-013",
            "platform": "JILI"
        }
    },
    {
        "id": 42,
        "imgSrc": "JILI-TABLE-012.png",
        "title": "Dragon & Tiger",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-012",
            "platform": "JILI"
        }
    },
    {
        "id": 43,
        "imgSrc": "jili_TeenPattiJoker.png",
        "title": "Teen Patti Joker",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-010",
            "platform": "JILI"
        }
    },
    {
        "id": 44,
        "imgSrc": "JILI-TABLE-008.png",
        "title": "iRich Bingo",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-008",
            "platform": "JILI"
        }
    },
    {
        "id": 46,
        "imgSrc": "JILI-TABLE-009.png",
        "title": "Callbreak",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-009",
            "platform": "JILI"
        }
    },
    {
        "id": 47,
        "imgSrc": "JILI-TABLE-003.png",
        "title": "Andar Bahar",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-003",
            "platform": "JILI"
        }
    },
    {
        "id": 48,
        "imgSrc": "JILI-TABLE-004.png",
        "title": "Rummy",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-004",
            "platform": "JILI"
        }
    },
    {
        "id": 50,
        "imgSrc": "JILI-TABLE-005.png",
        "title": "Number King",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-005",
            "platform": "JILI"
        }
    },
    {
        "id": 49,
        "imgSrc": "JILI-TABLE-027.png",
        "title": "Golden Land",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-027",
            "platform": "JILI"
        }
    },
    {
        "id": 52,
        "imgSrc": "JILI-TABLE-033.png",
        "title": "Mines",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-033",
            "platform": "JILI"
        }
    },
    {
        "id": 53,
        "imgSrc": "JILI-TABLE-006.png",
        "title": "Poker King",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-006",
            "platform": "JILI"
        }
    },
    {
        "id": 54,
        "imgSrc": "JILI-TABLE-007.png",
        "title": "Poker King",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-007",
            "platform": "JILI"
        }
    },
    {
        "id": 55,
        "imgSrc": "JILI-TABLE-028.png",
        "title": "Pappu",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-028",
            "platform": "JILI"
        }
    },
    {
        "id": 55,
        "imgSrc": "JILI-TABLE-035.png",
        "title": "Limbu",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-035",
            "platform": "JILI"
        }
    },
    {
        "id": 55,
        "imgSrc": "JILI-TABLE-036.png",
        "title": "Wheel",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-036",
            "platform": "JILI"
        }
    },
    {
        "id": 56,
        "imgSrc": "JILI-TABLE-color.png",
        "title": "Color Prediction",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-039",
            "platform": "JILI"
        }
    },
    {
        "id": 57,
        "imgSrc": "JILI-TABLE-032.png",
        "title": "Hilo",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-032",
            "platform": "JILI"
        }
    },
    {
        "id": 58,
        "imgSrc": "JILI-TABLE-gorush.png",
        "title": "Go Rush",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-030",
            "platform": "JILI"
        }
    },
    {
        "id": 59,
        "imgSrc": "JILI-TABLE-tower.png",
        "title": "Tower",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-031",
            "platform": "JILI"
        }
    },
    {
        "id": 59,
        "imgSrc": "JILI-TABLE-002.png",
        "title": "Tower",
        "category": [
            "Table",
            "popular"
        ],
        "plateform": [
            "JILLI"
        ],
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-002",
            "platform": "JILI"
        }
    },
    {
        "id": 60,
        "imgSrc": "KM-SLOT-001.png",
        "title": "Sugar Blast",
        "category": [
            "slot"
        ],
        "plateform": [
            "KINGMAKER"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "KM-SLOT-001",
            "platform": "KINGMAKER"
        }
    },
    {
        "id": 61,
        "imgSrc": "JILI-SLOT-woldcup.png",
        "title": "World Cup",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-063",
            "platform": "JILI"
        }
    },
    {
        "id": 62,
        "imgSrc": "JILI-SLOT-086.png",
        "title": "Bangla Beauty",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-086",
            "platform": "JILI"
        }
    },
    {
        "id": 64,
        "imgSrc": "JILI-SLOT-crazytaxi.png",
        "title": "Happy Taxi",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-056",
            "platform": "JILI"
        }
    },
    {
        "id": 66,
        "imgSrc": "JILI-SLOT-057.png",
        "title": "Gold Rush",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-057",
            "platform": "JILI"
        }
    },
    {
        "id": 67,
        "imgSrc": "JILI-SLOT-crazy-pusher.png",
        "title": "Crazy Pusher",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-054",
            "platform": "JILI"
        }
    },
    {
        "id": 67,
        "imgSrc": "JILI-SLOT-053.png",
        "title": "Book of Gold",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-053",
            "platform": "JILI"
        }
    },
    {
        "id": 69,
        "imgSrc": "JILI-SLOT-071.png",
        "title": "Neko Fortune",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-071",
            "platform": "JILI"
        }
    },
    {
        "id": 70,
        "imgSrc": "JILI-SLOT-051.png",
        "title": "Mega Ace",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-051",
            "platform": "JILI"
        }
    },
    {
        "id": 71,
        "imgSrc": "JILI-SLOT-thorx.png",
        "title": "thorx",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-052",
            "platform": "JILI"
        }
    },
    {
        "id": 72,
        "imgSrc": "JILI-SLOT-hotchili.png",
        "title": "Hot Chilli",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-002",
            "platform": "JILI"
        }
    },
    {
        "id": 73,
        "imgSrc": "JILI-SLOT-gaja.png",
        "title": "Chin Shi Huang",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-003",
            "platform": "JILI"
        }
    },
    {
        "id": 74,
        "imgSrc": "JILI-SLOT-dargons.png",
        "title": "War of Dragons",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-004",
            "platform": "JILI"
        }
    },
    {
        "id": 75,
        "imgSrc": "JILI-SLOT-fortunetree.png",
        "title": "Fortune Tree",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-005",
            "platform": "JILI"
        }
    },
    {
        "id": 75,
        "imgSrc": "JILI-SLOT-luckyball.png",
        "title": "Lucky Ball",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-006",
            "platform": "JILI"
        }
    },
    {
        "id": 77,
        "imgSrc": "JILI-SLOT-007.png",
        "title": "Hyper Burst",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-007",
            "platform": "JILI"
        }
    },
    {
        "id": 78,
        "imgSrc": "JILI-SLOT-008.png",
        "title": "Shanghai Beauty",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-008",
            "platform": "JILI"
        }
    },
    {
        "id": 79,
        "imgSrc": "JILI-SLOT-777.png",
        "title": "SevenSevenSeven",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-013",
            "platform": "JILI"
        }
    },
    {
        "id": 80,
        "imgSrc": "JILI-SLOT-extra777.png",
        "title": "Crazy777",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-014",
            "platform": "JILI"
        }
    },
    {
        "id": 81,
        "imgSrc": "JILI-SLOT-015.png",
        "title": "Bubble Beauty",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-015",
            "platform": "JILI"
        }
    },
    {
        "id": 82,
        "imgSrc": "JILI-SLOT-baochin.png",
        "title": "Bao boon chin",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-016",
            "platform": "JILI"
        }
    },
    {
        "id": 81,
        "imgSrc": "JILI-SLOT-crazyfafa.png",
        "title": "crazyfafafa",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-017",
            "platform": "JILI"
        }
    },
    {
        "id": 81,
        "imgSrc": "JILI-SLOT-xiyang.png",
        "title": "XiYangYang",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-018",
            "platform": "JILI"
        }
    },
    {
        "id": 82,
        "imgSrc": "JILI-SLOT-fortune-pig.png",
        "title": "FortunePig",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-019",
            "platform": "JILI"
        }
    },
    {
        "id": 83,
        "imgSrc": "JILI-SLOT-candybaby.png",
        "title": "Candy Baby",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-020",
            "platform": "JILI"
        }
    },
    {
        "id": 84,
        "imgSrc": "JILI-SLOT-diamondparty.png",
        "title": "DiamondParty",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-021",
            "platform": "JILI"
        }
    },
    {
        "id": 86,
        "imgSrc": "JILI-SLOT-feng-shen.png",
        "title": "GoldenBank",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-023",
            "platform": "JILI"
        }
    },
    {
        "id": 87,
        "imgSrc": "JILI-SLOT-023.png",
        "title": "GoldenBank",
        "category": [
            "slots"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-023",
            "platform": "JILI"
        }
    },
    {
        "id": 88,
        "imgSrc": "JILI-SLOT-024.png",
        "title": "Lucky Goldbricks",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-024",
            "platform": "JILI"
        }
    },
    {
        "id": 89,
        "imgSrc": "JILI-SLOT-025.png",
        "title": "Dragon Treasure",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-025",
            "platform": "JILI"
        }
    },
    {
        "id": 90,
        "imgSrc": "JILI-SLOT-026.png",
        "title": "Charge Buffalo",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-026",
            "platform": "JILI"
        }
    },
    {
        "id": 91,
        "imgSrc": "JILI-SLOT-027.png",
        "title": "Super Ace",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-027",
            "platform": "JILI"
        }
    },
    {
        "id": 92,
        "imgSrc": "JILI-SLOT-028.png",
        "title": "Jungle King",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-028",
            "platform": "JILI"
        }
    },
    {
        "id": 93,
        "imgSrc": "JILI-SLOT-029.png",
        "title": "Money Comming",
        "category": [
            "slot",
            "Lottery"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-029",
            "platform": "JILI"
        }
    },
    {
        "id": 94,
        "imgSrc": "JILI-SLOT-030.png",
        "title": "Golden Queen",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-030",
            "platform": "JILI"
        }
    },
    {
        "id": 95,
        "imgSrc": "JILI-SLOT-031.png",
        "title": "Boxing King",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-031",
            "platform": "JILI"
        }
    },
    {
        "id": 96,
        "imgSrc": "JILI-SLOT-039.png",
        "title": "Secret Treasure",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-039",
            "platform": "JILI"
        }
    },
    {
        "id": 97,
        "imgSrc": "JILI-SLOT-040.png",
        "title": "RomaX",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-040",
            "platform": "JILI"
        }
    },
    {
        "id": 98,
        "imgSrc": "JILI-SLOT-041.png",
        "title": "Super Rich",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-041",
            "platform": "JILI"
        }
    },
    {
        "id": 99,
        "imgSrc": "JILI-SLOT-042.png",
        "title": "Golden Empire",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-042",
            "platform": "JILI"
        }
    },
    {
        "id": 100,
        "imgSrc": "JILI-SLOT-043.png",
        "title": "Fortune Gems",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-043",
            "platform": "JILI"
        }
    },
    {
        "id": 101,
        "imgSrc": "JILI-SLOT-044.png",
        "title": "Party Night",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-044",
            "platform": "JILI"
        }
    },
    {
        "id": 102,
        "imgSrc": "JILI-SLOT-045.png",
        "title": "Crazy Hunter",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-045",
            "platform": "JILI"
        }
    },
    {
        "id": 103,
        "imgSrc": "JILI-SLOT-046.png",
        "title": "Magic Lamp",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-046",
            "platform": "JILI"
        }
    },
    {
        "id": 104,
        "imgSrc": "JILI-SLOT-047.png",
        "title": "TWINWINS",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-047",
            "platform": "JILI"
        }
    },
    {
        "id": 105,
        "imgSrc": "JILI-SLOT-048.png",
        "title": "Agent Ace",
        "category": [
            "slot"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-048",
            "platform": "JILI"
        }
    },
    {
        "id": 106,
        "imgSrc": "JILI-FISH-001.png",
        "title": "Royal Fishing",
        "category": [
            "Fishing"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-001",
            "platform": "JILI"
        }
    },
    {
        "id": 107,
        "imgSrc": "JILI-FISH-002.png",
        "title": "Royal Fishing",
        "category": [
            "Fishing"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-002",
            "platform": "JILI"
        }
    },
    {
        "id": 108,
        "imgSrc": "JILI-FISH-003.png",
        "title": "Jackpot Fishing",
        "category": [
            "Fishing"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-003",
            "platform": "JILI"
        }
    },
    {
        "id": 109,
        "imgSrc": "JILI-FISH-004.png",
        "title": "Dinosaur Tycoon",
        "category": [
            "Fishing"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-004",
            "platform": "JILI"
        }
    },
    {
        "id": 110,
        "imgSrc": "JILI-FISH-005.png",
        "title": "Happy Fishing",
        "category": [
            "Fishing"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-005",
            "platform": "JILI"
        }
    },
    {
        "id": 111,
        "imgSrc": "JILI-FISH-006.png",
        "title": "Dragon Fortune",
        "category": [
            "Fishing"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-006",
            "platform": "JILI"
        }
    },
    {
        "id": 112,
        "imgSrc": "JILI-FISH-007.png",
        "title": "Mega Fishing",
        "category": [
            "Fishing"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-007",
            "platform": "JILI"
        }
    },
    {
        "id": 113,
        "imgSrc": "JILI-FISH-008.png",
        "title": "Boom Legend",
        "category": [
            "Fishing"
        ],
        "plateform": [
            "JILI"
        ],
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-008",
            "platform": "JILI"
        }
    },
    {
        "id": 63,
        "imgSrc": "banner_sbe_aviator.png",
        "title": "Aviator",
        "category": [],
        "plateform": [
            "AVIATOR"
        ],
        "gameInfo": {
            "gameType": "",
            "gameCode": "3193817929df4f8481eb141698d331f0",
            "platform": "AVIATOR"
        }
    },
    {
        "id": 64,
        "imgSrc": "banner_sbe_aviator.png",
        "title": "Aviator Mobile",
        "category": [],
        "plateform": [
            "AVIATOR"
        ],
        "gameInfo": {
            "gameType": "",
            "gameCode": "5b1b299bf4f04b9f84643730a882e682",
            "platform": "AVIATOR"
        }
    },
    {
        "id": 65,
        "imgSrc": "banner_Aviator-jetx.png",
        "title": "JetX",
        "category": [],
        "plateform": [
            "AVIATOR"
        ],
        "gameInfo": {
            "gameType": "",
            "gameCode": "cfcd1ed15cf62e9cde42482e2dcc0699f2f30d6f",
            "platform": "AVIATOR"
        }
    },
    {
        "id": 66,
        "imgSrc": "banner_jetx.png",
        "title": "JetX3",
        "category": [],
        "plateform": [
            "AVIATOR"
        ],
        "gameInfo": {
            "gameType": "",
            "gameCode": "269a1ce0b5d04486a63139b9dd6dfe39",
            "platform": "AVIATOR"
        }
    },
    {
        "id": 67,
        "imgSrc": "banner_cricketx.png",
        "title": "CricketX",
        "category": [],
        "plateform": [
            "AVIATOR"
        ],
        "gameInfo": {
            "gameType": "",
            "gameCode": "9117a28ecefe54ebb9f812e1cbafdf0de9ace433",
            "platform": "AVIATOR"
        }
    },
    {
        "id": 115332,
        "imgSrc": "crazy-time.webp",
        "title": "Crazy Time",
        "category": [
            "Table"
        ],
        "plateform": [
            "EVOLUTION"
        ],
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "EVOLUTION-LIVE-006",
            "platform": "EVOLUTION"
        }
    },
    {
        "id": 68,
        "imgSrc": "aviatrix-casino.png",
        "title": "Aviatrix",
        "category": [],
        "plateform": [
            "AVIATOR"
        ],
        "gameInfo": {
            "gameType": "",
            "gameCode": "9d9b5b34389337d4e43568b4ba2d56be97de447a",
            "platform": "AVIATOR"
        }
    }
]

export const getCasinoObjVal = (gTitle, val) => {
    const fObj = casinoData.filter(i => i.title === gTitle)[0];
    if (val === 'platform' || val === 'gameCode' || val === 'gameType') {
        return fObj.gameInfo[val]
    } else {
        return fObj[val]
    }
};

export const getCasinoObj = (gTitle, getGameInfo) => {
    const fObj = casinoData2.filter(i => i.title === gTitle)[0];
    return {
        id: fObj?.id,
        title: fObj?.title,
        isLobby: !!fObj?.isLobby,
        ...fObj.gameInfo
    }
};

export const casinoError = {
    9998: "System Busy",
    9999: "Fail",
    11: "Do not have this platform under your agent.",
    1000: "Invalid user Id",
    1001: "Account existed",
    1002: "Account is not exists",
    1004: "Invalid Currency",
    1005: "language is not exists",
    1006: "PT Setting is empty!",
    1007: "Invalid PT setting with parent!",
    1008: "Invalid token!",
    1009: "Invalid timeZone",
    1010: "Invalid amount",
    1011: "Invalid txCode",
    1012: "Has Pending Transfer",
    1013: "Account is Lock",
    1014: "Account is Suspend",
    1016: "TxCode already operation!",
    1017: "TxCode is not exist",
    1018: "Not Enough Balance",
    1019: "No Data",
    1024: "Invalid date time format",
    1025: "Invalid transaction status",
    1026: "Invalid bet limit setting",
    1027: "Invalid Certificate",
    1028: "Unable to proceed. please try again later.",
    1029: "invalid IP address.\n" +
        "It might be IP address did not whitelist yet, or AWC cannot identify your AgentID",
    1030: "invalid Device to call API.(Ex.IE)",
    1031: "System is under maintenance.",
    1032: "Duplicate login.",
    1033: "Invalid Game",
    1034: "Time does not meet.",
    1035: "Invalid Agent Id.",
    1036: "Invalid parameters.",
    1037: "Invalid customer setting.\n" +
        "It might be your target CallbackURL not yet passed AWC test, please contact us for assistant.",
    1038: "Duplicate transaction.",
    1039: "Transaction not found.",
    1040: "Request timeout.",
    1041: "HTTP Status error.",
    1042: "HTTP Response is empty.",
    1043: "Bet has canceled.",
    1044: "Invalid bet.",
    1045: "Add account statement failed.",
    1046: "Transfer Failed! Please contact customer support immediately. Sorry for any inconvenience caused.",
    1047: "Game is under maintenance.",
    1054: "Invalid Platform",
    1056: "[any parameter]was empty",
}

export const casinoErrorMsg = (status) => {
    const fMsg = Object.keys(casinoError).filter((s) => s == status);
    const key = fMsg[0];
    const getErr = (fMsg.length > 0 && key) ? casinoError[key] : null;
    return getErr ? getErr : 'Unknown error';
}


export const getCasinoData = gTitle => casinoData2.filter(i=>i.title === gTitle)[0];



export const casinoModelPageLink = (navKey) =>  navKey ? `/game-play/casino?${queryString.stringify(getCasinoObj(navKey))}` : '/'


