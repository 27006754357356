import {casinoModelPageLink} from "../casino-utils";


export const casinoOptionsTabData = {
    Exclusive: [
        {
            id: 1,
            img: 'crazytime.jpg',
            name: 'Crazy Time',
            link: casinoModelPageLink("Crazy Time")
        },

        {
            id: 2,
            img: 'crashgame.png',
            name: 'Spaceman',
            link: casinoModelPageLink("Crash Bonus")
        },
        {
            id: 4,
            img: 'dicemaker.jpeg',
            name:'sicbo',
            link: casinoModelPageLink("sicbo")
        }
    ],
    Sports: [
        {
            name: 'exchange',
            img: 'icon-exchange.svg',
            link: '/d/dash/inplay',
        },
        {
            name: 'sportsBook',
            img: 'icon-sportbook.svg',
            link: casinoModelPageLink("SABA"),
        },
        {
            name: 'kabaddi',
            img: 'icon-kabaddi.svg',
            link: casinoModelPageLink("SABA"),
        },
        {
            name: 'basketBall',
            img: 'icon-basketball.svg',
            link: casinoModelPageLink("SABA"),
        },
        {
            name: 'football',
            img: 'icon-soccer.svg',
            link: casinoModelPageLink("SABA"),
        },
        {
            name: 'badminton',
            img: 'icon-badminton.svg',
            link: casinoModelPageLink("SABA"),
        },
        {
            name: 'mixParlay',
            img: 'icon-tennis.svg',
            link: casinoModelPageLink("SABA"),
        },
        {
            name: 'virtual',
            img: 'icon-virtual.svg',
            link: casinoModelPageLink("SABA"),
        },
        {
            name: 'horseRacing',
            img: 'icon-horsebook.svg',
            link: casinoModelPageLink("HORSE BOOK"),
        },
    ],
    Casino: [
        // {
        //     name: 'all',
        //     img: 'icon-all-provider.svg',
        //     // link: casinoLink({gameType: 'all'}),
        // },
        {
            name: 'evo',
            img: 'provider-evo.png',
            link: casinoModelPageLink("EVOLUTION"),
        },
        {
            name: 'sexy',
            img: 'provider-awcmsexy.png',
            link: casinoModelPageLink("AE Sexy"),
        },
        {
            name: 'pp',
            img: 'provider-awcmpp.png',
            link: casinoModelPageLink("pragmatics play")
        },
        {
            name: 'pt',
            img: 'provider-awcmpt.png',
            link: casinoModelPageLink("playtech"),
        },
        {
            name: 'venus',
            img: 'provider-awcmvenus.png',
            link: casinoModelPageLink("VENUS"),
        },
        {
            name: 'horseBook',
            img: 'icon-horsebook.svg',
            link: casinoModelPageLink("HORSE BOOK"),
        },
        {
            name: 'sv388',
            img: 'sv5224.png',
            link: casinoModelPageLink("Cock Fight PHI")
        },
    ],
    Slots: [
        {
            id: 1,
            img: 'icon-slot.png',
            name: 'All',
        },
        {
            id: 2,
            img: 'vendor-awcmjili.png',
            name: 'Jili',
        },
        {
            id: 3,
            img: 'vendor-pg.png',
            name: 'PG Soft',
        },
        {
            id: 4,
            img: 'vendor-awcmpp.png',
            name: 'Pragmatic Play',
        },
        {
            id: 5,
            img: 'vendor-jdb.png',
            name: 'JDB',
        },
        {
            id: 6,
            img: 'vendor-awcmfc.png',
            name: 'Fa Chai',
        },
        {
            id: 7,
            img: 'vendor-awcmsg.png',
            name: 'Spadegaming',
        },

        {
            id: 8,
            img: 'vendor-awcmsg.png',
            name: 'Spadegaming',
        },
        {
            id: 9,
            img: 'vendor-awcmrt.png',
            name: 'Red Tiger',
        },
        {
            id: 10,
            img: 'vendor-awcmfastspin.png',
            name: 'FastSpin',
        },
        {
            id: 11,
            img: 'vendor-awcmpt.png',
            name: 'Playtech',
        },
        {
            id: 12,
            img: 'vendor-awcmyesbingo.png',
            name: 'Yellow Bat',
        },
        {
            id: 13,
            img: 'vendor-rich88.png',
            name: 'Rich88',
        },
        {
            id: 14,
            img: 'vendor-worldmatch.png',
            name: 'WorldMatch',
        },
        {
            id: 15,
            img: 'vendor-cq9.png',
            name: 'CQ9',
        },
        {
            id: 16,
            img: 'vendor-joker.png',
            name: 'Joker',
        },
        {
            id: 17,
            img: 'vendor-playngo.png',
            name: 'PLAY‘N GO',
        },
        {
            id: 18,
            img: 'vendor-awcmp8.png',
            name: 'Play8',
        },
        {
            id: 19,
            img: 'vendor-netent.png',
            name: 'Netent',
        },
        {
            id: 20,
            img: 'vendor-mg.png',
            name: 'Microgaming',
        },
        {
            id: 21,
            img: 'vendor-nextspin.png',
            name: 'NextSpin',
        },
        {
            id: 22,
            img: 'vendor-lucky365.png',
            name: 'Lucky365',
        },
    ],
    Table: [
        {
            name: 'jili',
            img: 'provider-awcmjili.png',
            link: casinoModelPageLink("JILI"),
        },
        {
            name: 'pg',
            img: 'provider-pg.png',
            link: casinoModelPageLink("Pocket Games Soft"),
        },
        {
            name: 'km',
            img: 'provider-awcmkm.png',
            link: casinoModelPageLink("KINGMAKER"),
        },
        {
            name: 'ludo',
            img: 'provider-ludo.png',
            link: casinoModelPageLink("Ludo Bet"),
        },
        {
            name: 'sevenUp7Down',
            img: '7up7down.webp',
            link: casinoModelPageLink("7up7down"),
        },
        {
            name: 'sicbo',
            img: 'sicbo.webp',
            link: casinoModelPageLink("sicbo"),
        },
        {
            name: 'matka',
            img: 'cardmatka.webp',
            link: casinoModelPageLink("Card Matka"),
        },
    ],
    Fishing: [
        {
            name: 'jili',
            img: 'provider-awcmjili.png',
            link: casinoModelPageLink("JILI"),
        },
        {
            name: 'fc',
            img: 'FC.png',
            link: casinoModelPageLink("FC"),
        },
        {
            name: 'jdb',
            img: 'provider-jdb.png',
            link: casinoModelPageLink("JDB"),
        },
        {
            name: 'yl',
            img: 'yl.png',
            link: casinoModelPageLink("YL"),
        },
        {
            name: 'sg',
            img: 'provider-awcmsg.png',
            link: casinoModelPageLink("SPADE"),
        },
        {
            name: 'yesBingo',
            img: 'YesBingo.png',
            link: casinoModelPageLink("Yes Bingo"),
        },
    ],
    Arcade: [
        {
            name: 'e1Sport',
            img: 'esport.webp',
            link: casinoModelPageLink("E1SPORT"),
        },
        {
            name: 'fc',
            img: 'FC.png',
            link: casinoModelPageLink("FC"),
        },
        {
            name: 'yl',
            img: 'yl.png',
            link: casinoModelPageLink("YL"),
        },
        {
            name: 'sg',
            img: 'provider-awcmsg.png',
            link: casinoModelPageLink("SPADE"),
        },
    ],
    Lottery: [
        {
            name: 'km',
            img: 'provider-awcmkm.png',
            link: casinoModelPageLink("KINGMAKER"),
        },
        {
            name: 'money',
            img: 'money.png',
            link: casinoModelPageLink("Money Comming"),
        },
        {
            name: 'jili',
            img: 'provider-awcmjili.png',
            link: casinoModelPageLink("JILI"),
        },
        {
            name: 'rocket',
            img: 'cash-rocket.webp',
            link: casinoModelPageLink("Cash Rocket"),
        },
        {
            name: 'yesBingo',
            img: 'YesBingo.png',
            link: casinoModelPageLink("Yes Bingo"),
        },
    ]
}

export const getJsLocation = (objKey = null) => {
    if (typeof window !== 'undefined') {
        if (objKey) {
            return document.location[objKey];
        } else {
            return document.location;
        }
    } else return null;
}

export const getPublicImage = path => `${getJsLocation('origin')}/public/pub-images/${path}`;



