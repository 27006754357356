import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';
import axios from 'axios';
import { suid } from 'rand-token';
import registervid from '../images/Video/register-video.mp4'
import {
    onlyNumberRegex,
    specialCharacter,
    strongPassRegex,
    userNameRegex
} from "./reg-ex";

toast.configure();
const cookies = new Cookies();

const signUpUser = async payload => await axios.post(
    'https://liveapi247.live/api1/signup',
    payload,
    { validateStatus: (status) => status < 500 }
);

export default function RegisterMobile(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [viewpass, setViewpass] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        fullName: "",
        password: "",
        mobile: "",
        refCode: props?.refCode || '',
    });

    const [showVideo, setShowVideo] = useState(false);

    const onChange = (key, value) => setFormData({
        ...formData,
        [key]: value
    });

    const onSubmit = async () => {
        if (!userNameRegex.test(formData.username)) {
            toast.error('Username must have 4 to 15 characters without white space and any special characters');
        } else if (!formData.fullName) {
            toast.error('Please Enter Full Name.');
        } else if (!formData.mobile || !onlyNumberRegex.test(formData.mobile) || formData.mobile.length < 10) {
            toast.error('The Phone Number must have 10 digits.');
        } else if (!strongPassRegex.test(formData.password)) {
            toast.error('Password must have 8 to 15 alphanumeric characters.');
        } else if (!formData.refCode) {
            toast.error('Please Enter Referral code.');
        } else {
            setIsLoading(true);
            let token = suid(16);
            await signUpUser({ ...formData, token }).then(({ data, status }) => {
                setIsLoading(false);
                if (status === 200 && data === "Account created successfully!") {
                    toast.success('Signup Success');
                    cookies.set('id', formData.username, { path: '/' });
                    cookies.set('token', token, { path: '/' });
                    cookies.set('password', formData.password, { path: '/' });
                    setTimeout(() => window.location.href = '/home', 200);
                } else {
                    toast.error(data.message || "Something went wrong!");
                }
            });
        }
    };

    return (
        <>
            <body style={{ backgroundColor: '#141515', minHeight: '100vh' }}>
                <header className="login-head" style={{ border: "none" }}>
                    <Link to="/" className="close ui-link" onClick={() => props.setShowRegister(false)}></Link>
                    <h1>cricbet247</h1>
                </header>

                {/* Watch How to Register Link */}
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <button onClick={() => setShowVideo(true)} style={{ color: '#0af', textDecoration: 'underline', background: 'none', border: 'none' }}>
                        📹 Watch How to Register
                    </button>
                </div>

                <dl className="form-login">
                    <dd><input type="text" value={formData.username} onChange={(e) => onChange('username', e.target.value)} placeholder="Username" /></dd>
                    <dd><input type="text" value={formData.fullName} onChange={(e) => onChange('fullName', e.target.value)} placeholder="Full Name" /></dd>
                    <dd><input type="text" value={formData.mobile} onChange={(e) => onChange('mobile', e.target.value)} placeholder="Phone Number" /></dd>
                    <dd>
                        <input type={viewpass ? "text" : "password"} value={formData.password} onChange={(e) => onChange('password', e.target.value)} placeholder="Password" />
                        {viewpass ? (
                            <AiFillEye onClick={() => setViewpass(!viewpass)} style={{ position: 'absolute', left: '0px', fontSize: '23px' }} />
                        ) : (
                            <AiFillEyeInvisible onClick={() => setViewpass(!viewpass)} style={{ position: 'absolute', left: '77vw', fontSize: '6vw' }} />
                        )}
                    </dd>
                    <dd><input type="text" value={formData.refCode} onChange={(e) => onChange('refCode', e.target.value)} placeholder="Enter Referral Code here..." /></dd>
                    <dd>
                        <button onClick={onSubmit} className="btn-send ui-link" style={{ width: '100%', height: '11vw' }} disabled={isLoading}>
                            {isLoading ? 'Register...' : 'Register'}
                        </button>
                    </dd>
                </dl>

                {/* Video Modal */}
                {showVideo && (
                    <div style={{
                        position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <div style={{ background: '#ffffff40', padding: '20px', borderRadius: '8px', position: 'relative', maxWidth: '600px', width: '80%' }}>
                            {/* Close Button */}
                            <button onClick={() => setShowVideo(false)} style={{ position: 'absolute', top: '0px', right: '0px', background: 'red',borderRadius:'2vw', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}>✕</button>
                            
                            {/* Video Player */}
                            <video style={{borderRadius: '4vw'}} width="100%" controls autoPlay onEnded={() => setShowVideo(false)}>
                                <source src={registervid} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                )}
            </body>
        </>
    );
}
