import React, { useState, useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import axios from 'axios';
import {useBannerData} from "../serives/react-query/api-hooks";
import {getRqData} from "../serives/react-query/rq-helper";
import {RQ_KEY} from "../serives/react-query/constant";


const photos = []

const settings = {
    dots: false,
    slide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    className: 'slidesN'
}


export default function Carousel() {
    const [getBanners, setBanners] = useState([])


    // get Banner data
    let getBannerData = getRqData(RQ_KEY.STORE_BANNER_DATA);

    // enabled is on mount data get
    const { data } = useBannerData({
        // enabled: !getBannerData,
        select: (data) => data.data
    });


    useEffect(() => {
        // async function fetchData() {
        let val = getBannerData?.data;
        if (val && val.length !== 0) {
            let newResp = [{ url: val[0].banner1 }, { url: val[0].banner2 }, { url: val[0].banner3 }, { url: val[0].banner4 }, { url: val[0].banner5 }, { url: val[0].banner6 }, { url: val[0].banner7 }, { url: val[0].banner8 }, { url: val[0].banner9 }, { url: val[0].banner10 }]
            setBanners(newResp)
        }
        // }
        // fetchData()
    }, [getBannerData]);

    return (
        <React.Fragment>
              <div className="labeled-carousel__head" style={{paddingLeft:"3vw",background:"#141515"}}>
          <div className="labeled-carousel-label ng-star-inserted">
            <div className="labeled-carousel-label__title">Next Event</div>
            {/* <div className="labeled-carousel-label__navigation labeled-carousel-navigation">
              <div className="labeled-carousel-navigation__btn">
                <div 
                  className="labeled-carousel-navigation__icon" 
                  style={{ maskImage: 'url("https://img.b112j.com/bj/h5/assets/v3/images/icon-set/utility-type/arrow-left.svg?v=1738749244103")' }}
                ></div>
              </div>
              <div className="labeled-carousel-navigation__btn">
                <div 
                  className="labeled-carousel-navigation__icon" 
                  style={{ maskImage: 'url("https://img.b112j.com/bj/h5/assets/v3/images/icon-set/utility-type/arrow-right.svg?v=1738749244103")' }}
                ></div>
              </div>
            </div> */}
          </div>
        </div>
            <Slider
                {...settings}
                style={{ background:"#141515" }}

            >
                {getBanners.filter((x, i) => x.url !== null).map((photo, id) => {
                    return (
                        <div key={id} style={{background:"#141515"}}>
                            <img src={photo.url} alt="cric" style={{  width: '94vw',borderRadius:"1vw",margin:"auto", background:"#141515"}} />
                        </div>
                    )
                })}
            </Slider>
        </React.Fragment>
    )
}
