import React, { useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { sportsdata, casinoData, slotsData } from "./Constent";
import { casinoOptionsTabData } from "./Constent";
import CasinoCell from "./components/casino-cell/casino-cell";
// import {imgpath} from '../images/sports-icon/'


function MainNav({ isLoggedIn, openLogin }) {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('Exclusive'); // Default to Lottery tab


    const navItems = [
        { title: "Exclusive", image: "icon-exclusive-ani.png", onClick: () => null, },
        { title: "Sports", image: "icon-sport-ani.png", onClick: () => history.push('/d/dash/sports') },
        { title: "Casino", image: "icon-casino-ani.png" },
        { title: "Slots", image: "icon-slot-ani.png" },
        { title: "Table", image: "icon-table-ani.png" },
        { title: "Fishing", image: "icon-fish-ani.png" },
        // { title: "Crash", image: "icon-crash-ani.png" },
        { title: "Arcade", image: "icon-arcade-ani.png" },
        { title: "Lottery", image: "icon-lottery-ani.png" },
    ];



    return (
        <div className="main-page__game-nav-icon" style={{background:"#141515", paddingBottom: '1rem'}}>
            <div className="main-nav">
                <div
                    className="main-nav__category"
                    id="nav"
                    style={{ cursor: 'grab', userSelect: 'none', overflow: 'scroll' }}
                >
                    {navItems.map((item, index) => (
                        <button
                            key={item.title}
                            id={`mainNavBtn${item.id}`}
                            className={`${activeTab === item.title ? 'main-nav__btn main-nav-btn--active' : 'main-nav__btn'}`}
                            style={{ animationDelay: `${index * 0.25}s` }}
                            onClick={() => setActiveTab(item.title)}
                        >
                            <div className="main-nav-btn">
                                <div className="main-nav-btn__inner">
                                    <div
                                        className="main-nav-btn__img main-nav-btn__3d-img"
                                        style={{
                                            backgroundImage: `url(https://img.b112j.com/bj/h5/assets/v3/images/icon-set/menu-type/${item.image})`
                                        }} />
                                    <div className="main-nav-btn__text">{item.title}</div>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>

                <div className="main-nav__content-boundary">
                    <div className="main-nav__content-draggable">
                        <div className="main-nav__content">

                            {activeTab === "Exclusive" ?
                            <div
                                className="main-nav-content__inner ng-star-inserted main-nav-content__inner--active"
                            >
                                <div className="main-nav-games-banner">
                                    {casinoOptionsTabData[activeTab].map((item) => (
                                        <div
                                            key={item.img}
                                            className="main-nav-games-banner-cell ng-star-inserted"
                                            onClick={()=> isLoggedIn ? history.push(item.link) : null}
                                        >
                                            <div
                                                className="main-nav-games-banner-cell__icon"
                                            >
                                                <img
                                                    alt={item.img}
                                                    src={require(`../images/home-casino-options/${item.img}`)} // Assuming images are in public folder
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>: <div className="main-nav-content__inner main-nav-content__inner--active">
                                    <div className="main-nav-games-grid">
                                        {casinoOptionsTabData[activeTab].map((item, index) => (
                                            <CasinoCell key={item.img+index} img={item.img} name={item.name} history={history} isLoggedIn={isLoggedIn} openLogin={openLogin} link={item.link} />
                                        ))}

                                    </div>
                                </div> }




                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainNav;