import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
import {
  useCheckBookmakerData,
  useCheckFancyData,
  useCheckScoreData,
  useCheckTvData
} from "../serives/react-query/api-hooks";
import {sportsEventDataFilterCompetionName} from "../utils/sportsHelper";
import CompetionTab from "./components/competion-tab/competion-tab";
require('moment-timezone');

export default function SoccerMatches(props) {
  const match = props.data;

  const [competionDataList, setCompetionDataList] = useState({});
  useEffect(() => {
    if(match && match.length !== 0){
      sportsEventDataFilterCompetionName(match).then(cData=> {
        setCompetionDataList(cData.filterByGroupArr)
      })
    }

  }, [match]);


  // const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(false);
  // const [tv, settv] = useState([])
  // const [bookmaker, setbookmaker] = useState([])
  // const [ScoreData, setScoreData] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);

  var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";
  // vvvv

  const {data: tv } = useCheckTvData({  enabled: true, select: (data)=>  data.data});
  const {data: bookmaker } = useCheckBookmakerData({  enabled: true, select: (data)=>  data.data});
  const {data: ScoreData } = useCheckScoreData({  enabled: true, select: (data)=> data.data.data.ScoreID});


  // useEffect(() => {
  //
  //   // Axios.get(
  //   //   `https://liveapi247.live/api8/soccerData`)
  //   //
  //   //   .then(result => {
  //   //
  //   //     result.data.map(item => {
  //   //       item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
  //   //       return item;
  //   //     })
  //   //     result.data.sort(function (a, b) {
  //   //
  //   //       return moment(a.marketStartTime) - moment(b.marketStartTime);
  //   //     });
  //   //     setMatch(result.data);
  //   //     if (newrefresh) {
  //   //       setnewrefresh(false);
  //   //     }
  //   //   })
  //   //
  //   //
  //   //   .catch(error => console.log(error));
  //
  //   Axios.get(
  //     `https://liveapi247.live/api8/checktv`)
  //
  //     .then(result => {
  //
  //       settv(result.data);
  //     })
  //     .catch(error => console.log(error));
  //
  //   Axios.get(
  //     `https://liveapi247.live/api8/checkbookmaker`)
  //
  //     .then(result => {
  //
  //       setbookmaker(result.data);
  //     })
  //     .catch(error => console.log(error));
  //
  //
  //   Axios.get(
  //     `https://liveapi247.live/api8/getskyScoreId`)
  //
  //     .then(result => {
  //
  //       setScoreData(result.data.data.ScoreID);
  //     })
  //     .catch(error => console.log(error));
  //
  //
  // }, []);

  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");


    if (props.isLoggedIn == true) {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPINSC + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPINSC + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off";
        }
      }

    }


  }, [match, cricketUpdate]);

  const Multimarketpin = (index, event_id) => {
    if (props.isLoggedIn === false) {
      props.checkShowLogin(true)
    }
    else {
      var list = JSON.parse(localStorage.getItem("multi") || "[]");
      let ctr = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i] == event_id) {
          let j = i + 1;
          ctr = 1;
          for (; i < list.length; j++, i++) {
            list[i] = list[j];
          }
          list.splice([i - 1]);
        }
      }
      if (ctr == 0) {
        list.push(event_id);
      }
      localStorage.setItem("multi", JSON.stringify(list));
      setcricketUpdate(!cricketUpdate);

      // console.log('2', list);

    }
  }



  const matchMapData = (mathData)=> mathData.map(function (item, id) {
    let customScore = '';
    let elapsTime = '';
    let filteredData = ScoreData &&
        ScoreData.filter(x => String(x.eventId) === String(item.eventId));
    // let filteredData = [];
    if (filteredData && filteredData.length > 0) {
      customScore = filteredData[0].score;
      elapsTime = filteredData[0].timeElapsed
    }
    var showtv = false;
    var isbookmaker = false;

    var showColor = false;

    var matchTime;
    var today = moment();
    var tommorow = moment().add(1, 'day');

    if (tv && tv.includes(item.eventId)) {
      showtv = true;
    }
    if (bookmaker && bookmaker.includes(item.eventId)) {
      isbookmaker = true;

    }

    if (moment(item.marketStartTime).isSame(today, 'day')) {
      matchTime = moment(item.marketStartTime).format('LT');
    }
    else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
      matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
    }
    else {
      matchTime = item.marketStartTime;
    }

    if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
      showColor = true;
    }


    return (


        <li _ngcontent-njs-c67="" key={id} id="highlightEvent_29869541" className={`inplay${showColor ? "-on" : "-off"}`}>
          <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
            <dl>
              <dt>
                {moment() >= moment(item.marketStartTime) &&
                    <span _ngcontent-njs-c67="" id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}
                {isbookmaker &&
                    <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker ${showColor ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
                {moment() >= moment(item.marketStartTime) && <span _ngcontent-njs-c67="" id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium soccer</span>}
                {moment() >= moment(item.marketStartTime) &&
                    <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">In-Play {elapsTime}</span>}
                {moment() < moment(item.marketStartTime) && <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">{matchTime}</span>}
              </dt>

              {/* <span class="in_play">1 - 0</span> */}
              <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
            </dl>
          </Link>
          <a _ngcontent-njs-c67="" id={MKPINSC + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
        </li>
    )
  })
  // console.log('Soccer');
  return (
      <>
        {match && <div>
          {props.isByCompetion ?
              Object.keys(competionDataList).map((competionName)=>(
                  <>
                    <h4
                        style={{
                          "display": "flex",
                          "alignItems": "center",
                          "textIndent": "2.6666666667vw",
                          "background": "#eff3f5",
                          "borderTop": "1px solid #bfcad1",
                          "borderBottom": "1px solid #bfcad1",
                          "height": "8.5333333333vw"
                        }}
                    >{competionName}</h4>
                    {matchMapData(competionDataList[competionName])}
                  </>
              ))
              : matchMapData(match)}
        </div>}
      </>
  )
}
